import { Observable } from 'rxjs';
import { IsArray, IsBoolean, IsEnum, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, IsString, Min, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { PageMetadataSEO } from '../models/shared/common.shared';
import { ProductMarketplaceShared, ProductSearchShared, ProductShared } from '../models/shared/product.shared';
import { ProductDescriptions, ProductDto, ProductSaleDto, ProductType } from '../models/entities/product.dto';
import { ProductParamsDto } from '../models/entities/product-params.dto';
import { AttachmentShared } from '../models/shared/attachment.shared';
import { FilterScreen, FiltersType, ProductCategoryTemplate } from '../models/entities/product-category.dto';
import { ParamsShared } from '../models/shared/params.shared';
import { ProductAnalyticsDto } from '../models/entities/product-analytics.dto';

export enum ProductEndpointPaths {
    create = '/api/v1/product/create',
    edit = '/api/v1/product/edit',
    delete = '/api/v1/product/delete',
    moveToArchive = '/api/v1/product/moveToArchive',
    moveOutOfArchive = '/api/v1/product/moveOutOfArchive',
    publish = '/api/v1/product/publish',
    unpublish = '/api/v1/product/unpublish',
    search = '/api/v1/product/search',
    fetchAnalytics = '/api/v1/product/fetch-analytics',
    getById = '/api/v1/product/getById',
    getByIds = '/api/v1/product/getByIds',
    setParams = '/api/v1/product/setParams',
    setDiscount = '/api/v1/product/setDiscount',
    unsetDiscount = '/api/v1/product/unsetDiscount',
    updateESIndex = '/api/v1/product/updateESIndex',
    productSyncPricesWithCurrency = '/api/v1/product/productSyncPricesWithCurrency',
    generateProductDescriptions = '/api/v1/product/generateProductDescriptions'
}

export interface ProductEndpoint {
    create(request: ProductCreateRequest): Promise<ProductDto> | Observable<ProductDto>;
    edit(request: ProductEditRequest, req: Request): Promise<ProductDto> | Observable<ProductDto>;
    delete(request: ProductDeleteRequest): Promise<void> | Observable<void>;
    moveToArchive(request: ProductMoveToArchiveRequest): Promise<ProductDto> | Observable<ProductDto>;
    moveOutOfArchive(request: ProductMoveOutOfArchiveRequest): Promise<ProductDto> | Observable<ProductDto>;
    publish(request: ProductPublishRequest): Promise<ProductDto> | Observable<ProductDto>;
    unpublish(request: ProductUnpublishRequest): Promise<ProductDto> | Observable<ProductDto>;
    search(request: ProductSearchRequest): Promise<ProductSearchResponse> | Observable<ProductSearchResponse>;
    fetchAnalytics(request: ProductFetchAnalyticsRequest): Promise<ProductFetchAnalyticsResponse> | Observable<ProductFetchAnalyticsResponse>;
    getById(request: ProductGetByIdRequest): Promise<ProductDto> | Observable<ProductDto>;
    getByIds(request: ProductGetByIdsRequest): Promise<Array<ProductDto>> | Observable<Array<ProductDto>>;
    setParams(request: ProductSetParamsRequest): Promise<Array<ProductParamsDto>> | Observable<Array<ProductParamsDto>>;
    setDiscount(request: ProductSetDiscountRequest): Promise<void> | Observable<void>;
    unsetDiscount(request: ProductUnsetDiscountRequest): Promise<void> | Observable<void>;
    updateESIndex(request: ProductUpdateESIndexRequest): Promise<Array<ProductDto>> | Observable<Array<ProductDto>>;
    generateProductDescriptions(request: GenerateProductDescriptionsRequest): Promise<void> | Observable<void>;
}

export class ProductSetParamsEntryRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    paramId: number;

    @ApiModelProperty()
    @IsNotEmpty()
    paramValue: any;
}

export class ProductSetParamsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productId: number;

    @ApiModelProperty({
        type: ProductSetParamsEntryRequest,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    params: Array<ProductSetParamsEntryRequest>;
}

export class ProductFlagsBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isAvailableDepot: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isPopular: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isNew: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isCustomerChoice: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isHit: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    hasDiscounted: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    hasMarkingkits: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    shouldHideParameters: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    exportAvailableToMarket: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    exportAvailableDepotToMarket: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    autoExportToYandexIfAvailable: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    shouldHideUnderCut: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isOutOfProduction: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    withWarrantyFromManufacturer: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isAvailableVendorDepot: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isStaticAvailableVendorDepot: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isShowedOnHomepage: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    hasSale: boolean;

    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    enableAutoPriceUpdate: boolean;
}

export class ProductEntityBody {
    @ApiModelProperty({
        required: false,
    })
    @IsInt()
    @IsOptional()
    rank?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsInt()
    @IsOptional()
    rankGroup?: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProductType),
    })
    @IsEnum(ProductType)
    @IsNotEmpty()
    type: ProductType;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    manufacturerId: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    productCategoryId: number;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productConsumableIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productRecommendedIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productSameIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productAnalogsIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productAccessoriesIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productSeriesIds: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    tagIds: Array<number>;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    uuid?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    partNumber?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    vendorCode?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    vendorCode1C?: string;

    @ApiModelProperty()
    @IsNotEmpty()
    @IsString()
    title: string;

    @ApiModelProperty()
    @IsOptional()
    queryTitle?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    uri?: string;

    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    description: ProductDescriptions;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    descriptionHtml?: string;

    @ApiModelProperty({
        type: ProductFlagsBody,
    })
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    flags: Partial<ProductFlagsBody>;

    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    previewAttachmentId: number;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    sale?: ProductSaleDto ;

    @ApiModelProperty({
        type: AttachmentShared.Strategies.Request.ImageAttachment,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    imagesAttachmentIds: Array<AttachmentShared.Strategies.Request.ImageAttachment>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    fileAttachmentIds: Array<number>;

    @ApiModelProperty({
        type: ProductShared.ProductVideo,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsNotEmpty()
    videos: Array<ProductShared.ProductVideo>;

    @ApiModelProperty()
    @ValidateNested()
    @IsNotEmpty()
    seo: PageMetadataSEO;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    basePrice: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    minimalPrice: string;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsNotEmpty()
    basePriceCurrencyId: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    priceTax: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    tradePrice?: string;

    @IsOptional()
    @ApiModelProperty()
    @IsString()
    tradePriceAnnotation?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    priceComment?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    priceStep?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    displayDiscountFrom?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    displayDiscountPercents?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    warranty?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsObject()
    @IsOptional()
    json?: any;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    params?: ProductSetParamsRequest;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    exportRequest?: ProductMarketplaceShared.ExportRequest;

    @ApiModelProperty({
        required: false,
    })
    @Min(0)
    @IsInt()
    @IsOptional()
    hitsOrderMinimal?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsOptional()
    interIdAvailable?: number;

    @ApiModelProperty({
        required: false,
    })
    @IsOptional()
    interIdTotalAmount?: number;
}

export class ProductCreateRequest extends ProductEntityBody {
}

export class ProductEditRequest extends ProductEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductDeleteRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    ids: Array<number>;
}

export class ProductMoveToArchiveRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductMoveOutOfArchiveRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductPublishRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductUnpublishRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductSearchFiltersParamsRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    paramsId: number;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    paramsStaticValue?: string;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ParamsShared.ParamsType),
        required: false,
    })
    @IsEnum(ParamsShared.ParamsType)
    @IsOptional()
    type?: ParamsShared.ParamsType;
}

export class ProductSearchFiltersRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    ids?: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    excludeIds?: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    manufacturerIds?: Array<number>;

    @ApiModelProperty({
        required: false,
    })
    @IsInt()
    @IsOptional()
    template?: ProductCategoryTemplate;

    @IsOptional()
    @ApiModelProperty()
    @IsArray()
    @IsInt({ each: true })
    @IsPositive({ each: true })
    productCategoryIds?: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    tagIds?: Array<number>;

    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productSeriesIds?: Array<number>;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProductType),
        required: false,
    })
    @IsEnum(ProductType)
    @IsOptional()
    type?: ProductType;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    uri?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    uuid?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    partNumber?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    vendorCode?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    vendorCode1C?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    title?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    priceRangeMin?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    priceRangeMax?: string;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isEnabled?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isAvailableDepot?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isAvailable?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isNew?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isCustomerChoice?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isHit?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasDiscounted?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasMarkingkits?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isArchived?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isPopular?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    shouldHideParameters?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    exportAvailableToMarket?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    exportAvailableDepotToMarket?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    autoExportToYandexIfAvailable?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    shouldHideUnderCut?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isOutOfProduction?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasExportsToMarketplaces?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasExportToYandexMarket?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasExportToGoogle?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasExportToPP?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    withWarrantyFromManufacturer?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isAvailableVendorDepot?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isStaticAvailableVendorDepot?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isShowedOnHomepage?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasMinimalPriceViolation?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasSale?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isProductSeriesEnabledForFilter?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    isProductSeriesEnabledForSearch?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasPrice?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasNoPrice?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasNoPriceTax?: boolean;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    hasProductReviews?: boolean;

    @ApiModelProperty({
        required: false,
        type: ProductSearchFiltersParamsRequest,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsOptional()
    productParams?: Array<ProductSearchFiltersParamsRequest>;
}

export class ProductSearchSortRequest {
    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProductShared.ProductSearchSort),
    })
    @IsEnum(ProductShared.ProductSearchSort)
    @IsNotEmpty()
    field: ProductShared.ProductSearchSort;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProductShared.ProductSearchSortDirection),
    })
    @IsEnum(ProductShared.ProductSearchSortDirection)
    @IsNotEmpty()
    direction: ProductShared.ProductSearchSortDirection;
}

export class ProductSearchViewRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsPositive()
    @IsInt()
    @IsOptional()
    limit?: number;

    @ApiModelProperty({
        required: false,
    })
    @Min(0)
    @IsInt()
    @IsOptional()
    offset?: number;
}

export class ProductCountRequest {
    @ApiModelProperty({
        required: false,
    })
    @IsString()
    @IsOptional()
    queryString?: string;

    @ApiModelProperty({
        required: false,
    })
    @ValidateNested()
    @IsOptional()
    filters?: ProductSearchFiltersRequest;
}

// TODO: Add ApiModelProperty
export class ProductStatsResponse {
    categories: Array<{
        id: number;
        count: number;
    }>;
    manufacturers: Array<{
        id: number;
        count: number;
    }>;
}

export class ProductFiltersNavigateToParamsRequest {
    @ApiModelProperty()
    filtersType?: FiltersType;

    @ApiModelProperty()
    filterName?: string;

    @ApiModelProperty()
    paramId?: number;

    @ApiModelProperty()
    filterScreen?: FilterScreen;
}

export class ProductFetchAnalyticsRequest {
    @ApiModelProperty({
        required: false
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    view?: ProductSearchViewRequest;

    @ApiModelProperty({
        required: false,
        type: 'string',
        enum: Object.values(ProductSearchShared.Shape),
    })
    @IsEnum(ProductSearchShared.Shape)
    @IsOptional()
    shape?: ProductSearchShared.Shape;
}

export class ProductSearchRequest {
    @ApiModelProperty({
        required: false
    })
    @IsString()
    @IsOptional()
    queryString?: string;

    @ApiModelProperty({
        required: false
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    filters?: ProductSearchFiltersRequest;

    @ApiModelProperty({
        required: false,
        type: ProductSearchSortRequest,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @IsObject({ each: true })
    @IsArray()
    @IsOptional()
    sort?: Array<ProductSearchSortRequest>;

    @ApiModelProperty({
        required: false
    })
    @ValidateNested()
    @IsObject()
    @IsOptional()
    view?: ProductSearchViewRequest;

    @ApiModelProperty({
        required: false,
        type: 'string',
        enum: Object.values(ProductSearchShared.Shape),
    })
    @IsEnum(ProductSearchShared.Shape)
    @IsOptional()
    shape?: ProductSearchShared.Shape;
}

export class ProductSearchResponse {
    @ApiModelProperty({
        type: ProductDto,
        isArray: true,
    })
    products: Array<ProductDto>;

    @ApiModelProperty()
    totalSearchResults: number;
}

export class ProductFetchAnalyticsResponse {
    @ApiModelProperty({
        type: ProductAnalyticsDto,
        isArray: true,
    })
    products: Array<ProductAnalyticsDto>;

    @ApiModelProperty()
    total: number;
}

export class ProductGetByIdRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ProductGetByIdsRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    ids: Array<number>;
}

export class ProductSetDiscountRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    discount: number;

    @ApiModelProperty({
        type: 'string',
        enum: Object.values(ProductShared.MinimalPriceViolationStrategy),
    })
    @IsEnum(ProductShared.MinimalPriceViolationStrategy)
    @IsNotEmpty()
    minimalPriceViolationStrategy: ProductShared.MinimalPriceViolationStrategy;
}

export class ProductUnsetDiscountRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;
}

export class GenerateProductDescriptionsRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;
}

export class ProductUpdateESIndexRequest {
    @ApiModelProperty({
        type: 'number',
        isArray: true,
    })
    @IsPositive({ each: true })
    @IsInt({ each: true })
    @IsArray()
    @IsNotEmpty()
    productIds: Array<number>;

    @ApiModelProperty({
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    skipWait?: boolean;
}
