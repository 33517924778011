export function moneyPipe(value: any): string {
    const round = Math.abs(Math.round(parseFloat(value))).toString();
    const sign = Math.round(parseFloat(value)) >= 0
        ? ''
        : '-';

    const sections = round.match(/(\d+?)(?=(\d{3})+(?!\d)|$)/g);

    return `${sign}${(sections || []).join(' ')}`;
}
