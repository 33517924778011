import {
    Component,
    computed,
    EventEmitter,
    input,
    Input,
    Output,
} from '@angular/core';
import { DadataConfig, DadataConfigDefault, DadataSuggestion, DadataType } from '@interid/interid-site-web/core';

@Component({
    selector: 'app-ngx-dadata-items',
    templateUrl: './purchase-basket-autocomplete-items.component.html',
    styleUrls: ['./purchase-basket-autocomplete-items.component.scss'],
})
export class NgxDadataItemsComponent{
    public daDataSuggestion = input<DadataSuggestion[]>([]);
    public config = input<DadataConfig>(DadataConfigDefault);
    public type = input(DadataType.address);

    @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();


    public data = computed(() => {
         return this.daDataSuggestion();
    });


    onClick(item: DadataSuggestion) {
        this.itemClick.emit(item);
    }
}
