import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductCardHelper } from '../../helpers/product-card.helper';

@Component({
    selector: 'app-shared-product-card-assets-rating-a',
    templateUrl: './product-card-rating-a.component.html',
    styleUrls: ['./product-card-rating-a.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardRatingAComponent {
    @Input() withRating = true;
    @Input() withReviews = true;
    @Input() withIsAvailableOnDepot = true;
    @Input() shouldShowNoReviewsMessage = true;
    @Input() shouldShowFiveEmptyStars = false;
    @Input() product: any;

    constructor(
        public readonly helper: ProductCardHelper,
    ) {
    }

    t(input: string): string {
        return `product_shared.assets.product_card_rating.${input}`;
    }

    get isAvailableNgClass(): any {
        return {
            'is-available-on-depot': this.helper.product && this.helper.product.flags.isAvailableDepot,
            'is-available-on-vendor-depot': this.helper.product && this.helper.product.flags.isAvailableVendorDepot && ! this.helper.product.flags.isAvailableDepot,
        };
    }
}
