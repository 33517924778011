import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsString, IsNotEmpty } from 'class-validator';
import { Observable } from 'rxjs';

export enum YandexGPTEndpointPaths {
    completion = '/api/v1/yandex-gpt/completion',
}

export interface YandexGPTEndpoint {
    completion(request: YandexGPTCompletionRequest): Promise<YandexGPTCompletionResponse> | Observable<YandexGPTCompletionResponse>;
}

export class YandexGPTCompletionRequest {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    productTitle: string;
}

export class YandexGPTCompletionResponse {
    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    text: string;
}
