<div class="c">
  <div class="c-rectangle-756">
    <div class="c-menu-top __app-fluid-container">
      <div class="c-frame-1">
        <div class="c-frame-1-1">
          <div class="city" id="attachCitySelectPopup" #attachCity (click)="openSelectDialog()">
            <img loading="lazy" src="/assets/images/u_map-marker_white.svg" />
            <span>
              {{ state.city?.variants?.nominative }}
            </span>
          </div>
        </div>
        <div class="c-frame-1-2">
          <a [routerLink]="['/delivery']"><span>Доставка</span></a>
          <a [routerLink]="['/payment-methods']"><span>Оплата</span></a>
        </div>
      </div>
      <div class="c-frame-2">
        <span>{{ resolvedData.constants.managersWorkTime }}</span>
        <div class="email">
          <a [href]="state.hrefEmail | trustUrl"
            ><span #emailForOrders>{{ this.resolvedData.constants.emailForOrders }}</span></a
            >
            <div class="website-tooltip">
              <img loading="lazy" src="/assets/images/copy_white.svg" (click)="copyEmailForOrders($event)" />
              <div id="copy-tooltip-3" class="website-tooltiptext"><span class="copy">Скопировать</span></div>
            </div>
          </div>
          <a class="___phone" [href]="state.city?.variants?.phone | phoneHref"
            ><span>{{ state.city?.variants?.phone }}</span></a
            >
          </div>
        </div>
      </div>
      <div class="c-section c-section-1">
        <div class="__app-fluid-container">
          <div class="c-section-container">
            <div class="c-section-block c-section-block-left">
              <div class="menu">
                <a class="solutions" [routerLink]="['/solutions']">
                  <div class="icon">
                    <img loading="lazy" src="/assets/images/solutions.svg" />
                  </div>
                  <span>
                    {{ t('main_menu.solutions') | translate }}
                  </span>
                </a>

                <a [routerLink]="['/about/vacancies']"
                  ><span>{{ t('main_menu.vacancies') | translate }}</span>
                  @if ((vacancies$ | async) && (vacancies$ | async) !== 0) {
                    <div class="counter">
                      <div class="numbers">
                        <span>{{ vacancies$ | async }}</span>
                      </div>
                    </div>
                  }
                </a>

                <a [routerLink]="['/articles']"
                  ><span>{{ t('main_menu.articles') | translate }}</span></a
                  >
                  <a [routerLink]="['/about']"
                    ><span>{{ t('main_menu.about') | translate }}</span></a
                    >
                    <a [routerLink]="['/contacts']"
                      ><span>{{ t('main_menu.contacts') | translate }}</span></a
                      >
                    </div>
                  </div>
                  <div class="c-section-block c-section-block-right">
                    <div class="c-section-block-menu">
                      <div class="c-messengers">
                        <span>Написать в мессенджер</span>
                        <a rel="nofollow" [href]="resolvedData.constants.telegram | trustUrl" target="_blank"><img loading="lazy" src="/assets/images/socials/telegram.svg" /></a>
                        <a rel="nofollow" [href]="resolvedData.constants.whatsapp | trustUrl" target="_blank"><img loading="lazy" src="/assets/images/socials/whatsapp.svg" /></a>
                      </div>
                      <div class="c-buttons">
                        <div class="c-button blue" (click)="quotationRequest()">
                          <img loading="lazy" src="/assets/images/portfolio-suitcase_icon.svg" />
                          <span>Запросить КП</span>
                        </div>
                        <div class="c-button red" (click)="phoneCallRequest()">
                          <img loading="lazy" src="/assets/images/call_icon.svg" />
                          <span>Заказать звонок</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-section c-section-3">
              <div class="__app-fluid-container">
                <div class="c-section-container">
                  <div class="c-section-block c-section-block-left">
                    <div class="catalog-buttons">
                      <div class="catalog-buttons-logo">
                        <a class="logo" [routerLink]="['/']">
                          <svg width="121" height="29" viewBox="0 0 121 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.65138 1.07417C5.24305 0.901369 5.8349 1.34509 5.8349 1.96147V28.5524H0V3.12566C0 2.71495 0.27099 2.35349 0.665229 2.23835L4.65138 1.07417Z" fill="#253238" />
                            <path d="M10.2656 14.9874C10.2656 12.5135 10.2021 10.4356 10.1104 8.64181H15.1903L15.4584 11.3998H15.5925C16.3686 10.0993 18.2454 8.19336 21.4062 8.19336C25.2585 8.19336 28.1301 10.9289 28.1301 16.7812V28.5532H22.274V17.5137C22.274 14.9426 21.4274 13.1936 19.3107 13.1936C17.688 13.1936 16.7284 14.3745 16.3051 15.5032C16.1499 15.9068 16.1075 16.4673 16.1075 17.0578V28.5457H10.2726V14.9874H10.2656Z" fill="#253238" />
                            <path d="M37.3677 3.22923C37.9595 3.05608 38.5517 3.49983 38.5517 4.11641V8.65029H42.7356V13.2021H38.5517V20.4222C38.5517 22.8289 39.1302 23.9351 40.8659 23.9351C41.6702 23.9351 42.0653 23.8902 42.6015 23.7706L42.6439 28.4645C41.9101 28.7485 40.4849 28.9802 38.8833 28.9802C36.9924 28.9802 35.4331 28.2926 34.4524 27.2611C33.38 26.0802 32.8297 24.1668 32.8297 21.3639V13.1946H30.332V8.64281H32.8297V5.2497C32.8297 4.83916 33.1004 4.47781 33.4945 4.36253L37.3677 3.22923Z" fill="#253238" />
                            <path d="M49.8677 20.5847C50.0229 23.1558 52.45 24.3816 55.1664 24.3816C57.1702 24.3816 58.7718 24.0976 60.3522 23.5295L61.1283 27.73C59.2163 28.5821 56.8527 29.0007 54.3127 29.0007C47.9204 29.0007 44.2656 25.0842 44.2656 18.8581C44.2656 13.8056 47.2289 8.21484 53.7765 8.21484C59.8583 8.21484 62.1514 13.2151 62.1514 18.1481C62.1514 19.2094 62.0385 20.1512 61.9538 20.5996L49.8677 20.5419V20.5847ZM56.7468 16.3318C56.7468 14.8221 56.126 12.2958 53.4307 12.2958C50.9825 12.2958 49.9806 14.7025 49.8254 16.3318H56.7468Z" fill="#253238" />
                            <path d="M65.1005 15.2038C65.1005 12.2813 65.0582 10.3679 64.9453 8.6414H69.2077C69.6156 8.6414 69.9532 8.95872 69.9783 9.36585L70.1593 12.2963H70.3146C71.2741 9.37387 73.546 8.14062 75.3945 8.14062C75.9307 8.14062 76.1988 8.18547 76.6222 8.26021V14.0901C76.1565 14.0228 75.712 13.9257 75.0417 13.9257C72.8828 13.9257 71.4364 15.1514 71.0342 17.0648C70.9707 17.4685 70.9213 17.9618 70.9213 18.4551V28.5527H65.0864V15.2038H65.1005Z" fill="#253238" />
                            <path d="M86.1882 14.3673C86.0922 13.6155 86.0441 13.2396 86.1613 12.9481C86.2642 12.6921 86.4517 12.4792 86.6926 12.3446C86.9669 12.1914 87.3458 12.1914 88.1037 12.1914H91.5578C92.3179 12.1914 92.6979 12.1914 92.9726 12.3452C93.2138 12.4802 93.4014 12.6939 93.5039 12.9506C93.6207 13.243 93.5713 13.6198 93.4725 14.3734L91.6274 28.449H87.988L86.1882 14.3673Z" fill="#253238" />
                            <path d="M97.0876 1.51914C99.2466 1.13795 102.055 0.90625 104.969 0.90625C109.936 0.90625 113.167 1.87042 115.615 3.82867C118.311 5.95135 120.004 9.32223 120.004 14.1879C120.004 19.4498 118.198 23.0599 115.728 25.3021C112.991 27.7088 108.8 28.8374 103.727 28.8374C100.7 28.8374 98.4916 28.6281 97.0664 28.4338V1.51914H97.0876ZM102.922 23.882C103.409 24.0016 104.256 24.0016 104.969 24.0016C110.317 24.0465 113.809 20.9372 113.809 14.3748C113.809 8.66449 110.648 5.64491 105.589 5.64491C104.319 5.64491 103.43 5.7645 102.937 5.88408V23.8895H102.922V23.882Z" fill="#253238" />
                            <circle cx="89.8438" cy="4.68857" r="3.74131" fill="#69A042" />
                          </svg>
                        </a>
                      </div>
                      <div class="c-frame-1667 __hide-app-wide">
                        <span>info&#64;interid.ru</span>
                        <div class="line"></div>
                        <div class="phone"><span>+7 (495) 673-70-23</span></div>
                      </div>
                    </div>
                  </div>
                  <div class="c-frame-1546 __hide-app-desktop"><span>{{ resolvedData.constants.slogan }}</span></div>

                  <div class="c-section-block c-section-block-center __hide-app-desktop">
                    <app-shared-search></app-shared-search>
                  </div>
                  <div class="c-section-block c-section-block-right noindex">
                    <div class="favorites-and-basket">
                      @if (isSignedId) {
                        <a class="icon-button sign-in" [routerLink]="['/account']" rel="nofollow">
                          <div class="sign-in-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.71 12.71a6 6 0 1 0-7.42 0 10 10 0 0 0-6.22 8.18 1.006 1.006 0 1 0 2 .22 8 8 0 0 1 15.9 0 1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1 10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" fill="#253238" />
                            </svg>
                          </div>
                          <div class="sign-in-desc">
                            <div class="sign-in-title">
                              <span>{{ t('profile') | translate }}</span>
                            </div>
                          </div>
                        </a>
                      }
                      @if (!isSignedId) {
                        <div class="icon-button sign-in" (click)="signIn()">
                          <div class="sign-in-icon">
                            <div class="icon">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.71 12.71a6 6 0 1 0-7.42 0 10 10 0 0 0-6.22 8.18 1.006 1.006 0 1 0 2 .22 8 8 0 0 1 15.9 0 1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1 10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" fill="#253238" />
                              </svg>
                            </div>
                          </div>
                          <div class="sign-in-desc">
                            <div class="sign-in-title">
                              <span>{{ t('signIn') | translate }}</span>
                            </div>
                          </div>
                        </div>
                      }
                      <a class="compares" rel="nofollow" [routerLink]="['/compares']">
                        <div class="compares-icon">
                          <div class="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 12a1 1 0 0 0-1 1v8a1 1 0 1 0 2 0v-8a1 1 0 0 0-1-1Zm5-10a1 1 0 0 0-1 1v18a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1Zm10 14a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1Zm-5-8a1 1 0 0 0-1 1v12a1 1 0 0 0 2 0V9a1 1 0 0 0-1-1Z" fill="#253238" />
                            </svg>
                          </div>
                        </div>
                        <div class="compares-desc">
                          @if ((comparesCount$ | async) > 0 && isBrowser) {
                            <div class="compares-count">
                              <span>{{ comparesCount$ | async }}</span>
                            </div>
                          }
                          <div class="compares-title">
                            <span>{{ t('compares') | translate }}</span>
                          </div>
                        </div>
                      </a>
                      <a class="favorites" rel="nofollow" [routerLink]="['/favorites']">
                        <div class="favorites-icon">
                          <div class="icon">
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M19.16 2.61A6.27 6.27 0 0 0 11 2a6.27 6.27 0 0 0-8.16 9.48l7.45 7.45a1.001 1.001 0 0 0 1.42 0l7.45-7.45a6.27 6.27 0 0 0 0-8.87Zm-1.41 7.46L11 16.81l-6.75-6.74a4.28 4.28 0 0 1 3-7.3 4.25 4.25 0 0 1 3 1.25 1 1 0 0 0 1.42 0 4.27 4.27 0 0 1 6 6.05h.08Z" fill="#253238" />
                            </svg>
                          </div>
                        </div>
                        <div class="favorites-desc">
                          @if ((favoritesCount$ | async) > 0 && isBrowser) {
                            <div class="favorites-count">
                              <span>{{ favoritesCount$ | async }}</span>
                            </div>
                          }
                          <div class="favorites-title">
                            <span>{{ t('favorites') | translate }}</span>
                          </div>
                        </div>
                      </a>
                      <a class="basket" rel="nofollow" [routerLink]="['/purchase']">
                        <div class="basket-icon">
                          <div class="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21 16H7a1 1 0 0 1 0-2h10.44a3 3 0 0 0 2.91-2.27L22 5.24a1 1 0 0 0-.18-.86A1 1 0 0 0 21 4H6.76a3 3 0 0 0-2.82-2H3a1 1 0 0 0 0 2h.94a1 1 0 0 1 1 .76l.06.48L6.73 12A3.003 3.003 0 1 0 7 18h.18a3 3 0 1 0 5.64 0h2.36a3 3 0 1 0 5.64 0H21a1 1 0 0 0 0-2ZM19.72 6l-1.31 5.24a1 1 0 0 1-1 .76H8.78l-1.5-6h12.44ZM10 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm8 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" fill="#253238" />
                            </svg>
                          </div>
                        </div>
                        <div class="basket-desc">
                          @if ((basketCount$ | async) > 0 && isBrowser) {
                            <div class="basket-count">
                              <span>{{ basketCount$ | async }}</span>
                            </div>
                          }
                          <div class="basket-title">
                            <span>{{ t('basket') | translate }}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-section c-section-4">
              <div class="__app-fluid-container">
                <app-header-desktop-menu></app-header-desktop-menu>
              </div>
            </div>

            <div class="c-section-block c-section-block-center __hide-app-wide">
              <div class="__app-fluid-container">
                <app-shared-search></app-shared-search>
              </div>
            </div>
          </div>
