import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, input, OnDestroy, PLATFORM_ID, signal, viewChild } from '@angular/core';
import { ContentBlockHTML } from '@interid/interid-site-shared';
import { H1Service, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-content-block-html',
    templateUrl: './content-block-html.component.html',
    styleUrls: ['./content-block-html.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockHtmlComponent implements OnDestroy {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public payload = input<ContentBlockHTML>();
    public isArticle = input(false);

    get html$() {
        const payload: [Observable<string>] = [this.h1.h1$];

        return combineLatest(payload).pipe(
            map(([h1]) => {
                return this.payload().contents.split('{{ h1 }}').join(h1);
            })
        );
    }

    constructor(
        private readonly h1: H1Service
    ) { }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `content.components.content.${input}`;
    }
}
