import { ProductMarketplacesDto } from '../models/entities/product-marketplaces.dto';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsInt, IsNotEmpty, IsPositive, Max, Min } from 'class-validator';
import { Observable } from 'rxjs';
import { ES_MAX_SEARCH_RESULTS_SIZE } from '../models/constants/es.constants'; 

export enum ProductMarketplacesEndpointPaths {
  list = '/api/v1/product-marketplaces/list',
  yandexXML = '/api/v1/product-marketplaces/yandexXML',
  yandexGoodsXML = '/api/v1/product-marketplaces/yandexGoodsXML',
  googleXML = '/api/v1/product-marketplaces/googleXML',
  productsXML = '/api/v1/product-marketplaces/productsXML',
}

export enum ProductMarketplacesErrorCodes {
}

export interface ProductMarketplacesEndpoint {
  list(request: ProductMarketplacesListRequest): Promise<ProductMarketplacesListResponse> | Observable<ProductMarketplacesListResponse>;
  yandexXML(res: any): Promise<void> | Observable<void>;
  yandexGoodsXML(res: any): Promise<void> | Observable<void>;
  googleXML(res: any): Promise<any> | Observable<any>;
  productsXML(res: any): Promise<any> | Observable<any>;
}

export class ProductMarketplacesListRequest {
  @ApiModelProperty()
  @Min(0)
  @IsInt()
  @IsNotEmpty()
  offset: number;

  @ApiModelProperty()
  @Min(1)
  @Max(ES_MAX_SEARCH_RESULTS_SIZE)
  @IsPositive()
  @IsInt()
  @IsNotEmpty()
  limit: number;
}

export class ProductMarketplacesListResponse {
  @ApiModelProperty({
    type: ProductMarketplacesDto,
    isArray: true,
  })
  items: Array<ProductMarketplacesDto>;

  @ApiModelProperty()
  total: number;
}
