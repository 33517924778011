/* eslint-disable @typescript-eslint/no-explicit-any */
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, PLATFORM_ID, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { InteridCmsCityDataAccess } from '@interid/interid-site-data-access/cms';
import { CityDto, ViewBreakpointsShared, withoutEndingSlash, withTrailingSlash } from '@interid/interid-site-shared';
import { AppBootstrapDataService, defaultCityOverlayConfig, EnvironmentWebService, MessagesService, RouterConfiguration, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { Subject } from 'rxjs';
import { map, takeUntil, take, distinctUntilChanged } from 'rxjs/operators';
import { AppHeaderDesktopCityComponent } from '../app-header-desktop-city/app-header-desktop-city.component';
import { AppModalSelectCityService } from '../app-modal-select-city/app-modal-select-city.service';
import { AppMessageService } from '../app-message/app-message.service';
import { toSignal } from '@angular/core/rxjs-interop';

interface State {
    city: CityDto;
    remoteAddressCity: CityDto;
}

@Component({
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLayoutComponent implements AfterViewInit, OnDestroy {
    private readonly router = inject(Router);
    private readonly env = inject(EnvironmentWebService);
    private readonly appModalSelectCityService = inject(AppModalSelectCityService);
    private readonly cityEndpoint = inject(InteridCmsCityDataAccess);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly viewBreakpointsService = inject(ViewBreakpointsService);
    private readonly appBootstrap = inject(AppBootstrapDataService);
    private readonly appSession = inject(AppSessionService);
    private readonly appMessage = inject(AppMessageService);
    private readonly overlay = inject(Overlay);
    private platformId = inject(PLATFORM_ID);
    private messagesService = inject(MessagesService);
    private routerConfiguration = inject(RouterConfiguration);

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public state = signal<State>({
        city: new CityDto(),
        remoteAddressCity: new CityDto(),
    });

    public layout = toSignal(this.viewBreakpointsService.currentLayout$.pipe(
        distinctUntilChanged(),
        map((next) => next)
    ));

    public hasSiteMessage = toSignal(this.appBootstrap.data$.pipe(
        map((next) => !this.appMessage.isHidden && !!next.message && next.message.isSiteMessageEnabled && !!next.message.siteMessage)
    ));

    ngAfterViewInit(): void {
        if (this.routerConfiguration.scrollPositionRestoration == 'disabled') this.routerConfiguration.scrollPositionRestoration = 'top';

        if (isPlatformBrowser(this.platformId)) {
            this.appSession
                .isCookiePolicyAccepted$()
                .pipe(take(1))
                .subscribe((accepted) => {
                    if (!accepted) {
                        setTimeout(() => {
                            this.openCookieConsentPopup();
                        }, 5000);
                    }
                });

            this.appSession
                .hasSelectedCity$()
                .pipe(take(1))
                .subscribe((hasSelectedCity) => {
                    if (!hasSelectedCity) {
                        this.cityEndpoint
                            .cityByGeolocation()
                            .toPromise()
                            .then((next) => {
                                this.state().remoteAddressCity = next ? next : this.appBootstrap.data.cities.find((x) => x.isDefault);

                                const cityDomainEqualWithCurrentHostname = window.location.hostname == this.getCityDomain(this.state().remoteAddressCity);
                                if (!cityDomainEqualWithCurrentHostname || !next) {
                                    setTimeout(() => {
                                        this.openCityConfirmation();
                                    }, 5000); 
                                }
                            });
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    openCityConfirmation(disableConfirmButton = false): void {
        const detach$: Subject<void> = new Subject<void>();

        const overlay =
            this.viewBreakpointsService.currentLayout == ViewBreakpointsShared.Layout.Mobile
                ? this.overlay.create({
                      ...defaultCityOverlayConfig,
                      positionStrategy: this.overlay
                          .position()
                          .flexibleConnectedTo(document.getElementById('attachCitySelectPopupMobile'))
                          .withPositions([
                              {
                                  originX: 'start',
                                  originY: 'bottom',
                                  offsetX: 16,
                                  offsetY: 16,
                                  overlayX: 'start',
                                  overlayY: 'top',
                              },
                          ])
                          .withFlexibleDimensions(false)
                          .withPush(false),

                      hasBackdrop: false,
                      scrollStrategy: this.overlay.scrollStrategies.reposition(),
                  })
                : this.overlay.create({
                      ...defaultCityOverlayConfig,
                      positionStrategy: this.overlay
                          .position()
                          .flexibleConnectedTo(document.getElementById('attachCitySelectPopup'))
                          .withPositions([
                              {
                                  originX: 'start',
                                  originY: 'bottom',
                                  offsetX: 0,
                                  offsetY: 16,
                                  overlayX: 'start',
                                  overlayY: 'top',
                              },
                          ])
                          .withFlexibleDimensions(false)
                          .withPush(false),

                      hasBackdrop: false,
                      scrollStrategy: this.overlay.scrollStrategies.reposition(),
                  });

        const componentPortal = new ComponentPortal(AppHeaderDesktopCityComponent);
        const overlayRef = overlay.attach(componentPortal);

        overlayRef.instance.disableConfirmButton.set(disableConfirmButton);
        overlayRef.instance.city.set(this.state().remoteAddressCity);

        overlayRef.instance.confirmCityEvent.pipe(takeUntil(detach$)).subscribe(() => {
            if (this.state().remoteAddressCity) {
                detach$.next();
                overlay.detach();

                const city = overlayRef.instance.city;
                const cityDomainEqualWithCurrentHostname = window.location.hostname == this.getCityDomain(this.state().remoteAddressCity);

                this.appSession
                    .setCity({ cityId: city().id })
                    .toPromise()
                    .then(() => {
                        if (!cityDomainEqualWithCurrentHostname) this.selectCity(city());
                    });
            }
        });

        overlayRef.instance.closeEvent.pipe(takeUntil(detach$)).subscribe(() => {
            detach$.next();
            overlay.detach();
            this.cdr.markForCheck();
        });

        overlayRef.instance.selectCityEvent.pipe(takeUntil(detach$)).subscribe(() => {
            detach$.next();
            overlay.detach();

            this.openSelectDialog();
        });

        overlay
            .backdropClick()
            .pipe(takeUntil(detach$))
            .subscribe(() => {
                detach$.next();
                overlay.detach();
            });
    }

    openSelectDialog(): void {
        this.appModalSelectCityService.openSelectCityDialog();
    }

    selectCity(city: CityDto): void {
        this.appSession
            .setCity({
                cityId: city.id,
            })
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe({
                next: () => {
                    if (location) {
                        if (this.env.current.enableSwitchCityDomain) {
                            location.href = this.getCityLink(city);
                        } else {
                            location.reload();
                        }
                    }
                },
                // complete: () => this.cdr.markForCheck()
            }
        );
    }

    getCityLink(city: CityDto): any {
        const url = withoutEndingSlash(withTrailingSlash(this.router.url));

        if (city.isDefault) {
            return `${this.env.current.baseDomainProtocol}://${this.env.current.baseDomain}${url}`;
        } else {
            return `${this.env.current.baseDomainProtocol}://${city.domain}.interid.ru${url}`;
        }
    }

    getCityDomain(city: CityDto): any {
        if (city.isDefault) {
            return `${this.env.current.baseDomain}`;
        } else {
            return `${city.domain}.interid.ru`;
        }
    }

    openCookieConsentPopup(): void {
        this.messagesService.cookie({
            message: '',
            translate: false,
            duration: 0,
        });
    }    
}
