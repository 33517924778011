import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Source } from '@interid/interid-site-shared';
import { UrlBuilderService } from '@interid/interid-site-web/core';
import { ProductDetailsResolveData } from 'libs/interid-site-web/feature-product/src/lib/layouts/product-details/product-details.resolve';

@Component({
    selector: 'app-product-manufacturer-dealer',
    templateUrl: './product-manufacturer-dealer.component.html',
    styleUrls: ['./product-manufacturer-dealer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductManufacturerDealerComponent implements OnInit {
 
    @Input() resolvedData: ProductDetailsResolveData = {} as ProductDetailsResolveData;
    cleanedDealerDescription: SafeHtml = '';

    constructor(
        private readonly urlBuilder: UrlBuilderService,
        private readonly sanitizer: DomSanitizer,
    ) {}

    ngOnInit(): void {
        const rawHtml = this.stripHtml(this.resolvedData?.manufacturer?.dealerDescription || '');
        this.cleanedDealerDescription = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
    }
    

    get hasManufacturer(): boolean {
        return this.resolvedData.manufacturer?.isDealer === false;
    }
    
    get hasDealer(): boolean {
        return this.resolvedData.manufacturer?.isDealer === true;
    }

    private stripHtml(html: string): string {
        return html.replace(/<(?!img\b)[^>]+>/gi, '');
    }
    
    get productRouterLink(): string | null {
        if (!this.resolvedData?.product?.id) return null;
        try {
            return this.urlBuilder.urlLink({
                type: Source.Product,
                payload: { productId: this.resolvedData.product.id },
            });
        } catch (error) {
            console.error('Error generating productRouterLink:', error);
            return null;
        }
    }
    
    get manufacturerRouterLink(): string | null {
        if (!this.resolvedData?.manufacturer?.uri) return null;
        try {
            return this.urlBuilder.urlLink({
                type: Source.Manufacturer,
                payload: { vendorUrl: this.resolvedData.manufacturer.uri },
            });
        } catch (error) {
            console.error('Error generating manufacturerRouterLink:', error);
            return null;
        }
    }
}
