import { IsArray, IsBoolean, IsEnum, IsInt, IsNotEmpty, IsNumber, IsObject, IsOptional, IsPositive, IsString, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ProductSearchFiltersRequest } from '../../endpoints/product.endpoint';
import { AttachmentDto } from '../entities/attachment.dto';

export namespace ProductShared {
    export enum ProductSearchSort {
        ElasticScore = '_score',
        Rank = 'rank',
        RankGroup = 'rankGroup',
        Id = 'id',
        UUID = 'uuid',
        DateCreated = 'dateCreated',
        DateUpdated = 'dateUpdated',
        DatePublished = 'datePublished',
        DateArchived = 'dateArchived',
        Manufacturer = 'manufacturer',
        PartNumber = 'partNumber',
        VendorCode = 'vendorCode',
        VendorCode1C = 'vendorCode1C',
        Title = 'title',
        Price = 'price',
        Type = 'type',
        ProductCategory = 'productCategory',
        MinimalPrice = 'minimalPrice',
        BasePrice = 'basePrice',
        IsEnabled = 'isEnabled',
        IsAvailableDepot = 'isAvailableDepot',
        IsAvailableVendorDepot  = 'IsAvailableVendorDepot',
        IsNew = 'isNew',
        IsCustomerChoice = 'isCustomerChoice',
        IsHit = 'isHit',
        IsPopular = 'isPopular',
        ShouldHideParameters = 'shouldHideParameters',
        ExportAvailableToMarket = 'exportAvailableToMarket',
        ExportAvailableDepotToMarket = 'exportAvailableDepotToMarket',
        AutoExportToYandexIfAvailable = 'autoExportToYandexIfAvailable',
        ShouldHideUnderCut = 'shouldHideUnderCut',
        IsOutOfProduction = 'isOutOfProduction',
        HasSale = 'hasSale',
        HasDiscounted = 'hasDiscounted',
        HasMarkingkits = 'hasMarkingkits',
        Hits = 'hits',
        Orders = 'orders',
        Favorites = 'favorites',
        Comments = 'comments',
    }

    export enum ProductSearchSortDirection {
        Asc = 'ASC',
        Desc = 'DESC',
    }

    export const allProductSearchSorts: Array<ProductSearchSort> = Object.values(ProductSearchSort);

    export enum MinimalPriceViolationStrategy {
        Skip = 'Skip',
        Ignore = 'Ignore',
        Abort = 'Abort',
        FloorToMinimal = 'FloorToMinimal',
        UpdateMinimalPrice = 'UpdateMinimalPrice',
    }

    export class ProductVideo {
        @ApiModelProperty({
            required: false,
        })
        @IsString()
        @IsOptional()
        title?: string;

        @ApiModelProperty()
        @IsString()
        @IsOptional()
        url?: string;

        @ApiModelProperty()
        @IsInt()
        @IsOptional()
        videoAttachmentId?: number;

        @ApiModelProperty()
        @IsObject()
        @IsOptional()
        videoAttachment?: AttachmentDto;
    }

    export class ProductFileMetadata {
        @ApiModelProperty()
        @IsPositive()
        @IsInt()
        @IsNotEmpty()
        attachmentId: number;

        @ApiModelProperty({
            required: false,
        })
        @IsString()
        @IsOptional()
        title?: string;

        @ApiModelProperty()
        @IsString()
        @IsOptional()
        description: string;
    }

    export class ProductImportFrom1CError {
        @ApiModelProperty()
        @IsString()
        @IsNotEmpty()
        uuid: string;

        @ApiModelProperty({
            required: false,
        })
        @IsOptional()
        errorCode?: any;

        @ApiModelProperty({
            required: false,
        })
        @IsString()
        @IsOptional()
        message?: string;
    }

    export enum ProductImportFrom1CStatus {
        NotStarted = 'NotStarted',
        InProgress = 'InProgress',
        Complete = 'Complete',
        Interrupted = 'Interrupted',
    }

    export const productAllFilterFlags: Array<keyof ProductSearchFiltersRequest> = [
        'isEnabled',
        'isAvailableDepot',
        'isAvailableVendorDepot',
        'hasSale',
        'hasDiscounted',
        'hasMarkingkits',
        'isCustomerChoice',
        'isHit',
        'isArchived',
        'isPopular',
        'exportAvailableToMarket',
        'exportAvailableDepotToMarket',
        'autoExportToYandexIfAvailable',
        'shouldHideParameters',
        'shouldHideUnderCut',
        'isOutOfProduction',
        'hasExportToYandexMarket',
        'hasExportToGoogle',
        'hasExportToPP',
        'hasMinimalPriceViolation',
    ];
}

export namespace ProductSearchShared {
    export enum Shape {
        IdOnly = 'IdOnly',
        UsedCategories = 'UsedCategories',
        UsedManufacturers = 'UsedManufacturers',
        UsedProductParams = 'UsedProductParams',
        UsedProductSeries = 'UsedProductSeries',
        CalculateOrderCosts = 'CalculateOrderCosts',
        ProductCategoriesInSales = 'ProductCategoriesInSales',
        ProductCategoriesInDiscounted = 'ProductCategoriesInDiscounted',
        ProductCategoriesInMarkingkits = 'ProductCategoriesInMarkingkits',
        AdminProductsList = 'AdminProductsList',
        AdminProductTransaction = 'AdminProductTransaction',
        AdminProductsCards = 'AdminProductsCards',
        ProductMarketplaces = 'ProductMarketplaces',
        ProductMarketplacesStats = 'ProductMarketplacesStats',
        FetchAnalytics = 'FetchAnalytics',
        ProductCatalogStats = 'ProductCatalogStats',
        SelectAllProducts = 'SelectAllProducts',
        WebProductCard = 'WebProductCard',
        WebProductCardWithParams = 'WebProductCardWithParams',
        WebProductCardWithoutParams = 'WebProductCardWithoutParams',
        WebProductCompare = 'WebProductCompare',
        WebProductBasket = 'WebProductBasket',
        WebSearchAutocomplete = 'WebSearchAutocomplete',
        WebProductConsumables = 'WebProductConsumables',
        WebProductModifications = 'WebProductModifications',
        IdAndTitle = 'IdAndTitle',
        WebHomepageProducts = 'WebHomepageProducts'
    }
}

export namespace ProductSaleShared {
    export const defaultPromoCode = 'SALE-iD';

    export enum ProductSaleStrategy {
        None = 'None',
        DiscountFixed = 'DiscountFixed',
        DiscountPercents = 'DiscountPercents',
        DiscountFixedForYandexMarketFeedback = 'DiscountFixedForYandexMarketFeedback',
        DiscountPercentsForYandexMarketFeedback = 'DiscountPercentsForYandexMarketFeedback',
        DiscountFixedForPromoCode = 'DiscountFixedForPromoCode',
        DiscountPercentsForPromoCode = 'DiscountPercentsForPromoCode',
    }

    export const allProductSaleStrategies: Array<ProductSaleStrategy> = Object.values(ProductSaleStrategy);

    export type ProductSaleStrategyPair =
        { type: ProductSaleStrategy.None; payload: ProductSalePayloadNone } |
        { type: ProductSaleStrategy.DiscountFixed; payload: ProductSalePayloadDiscountFixed } |
        { type: ProductSaleStrategy.DiscountPercents; payload: ProductSalePayloadDiscountPercents } |
        { type: ProductSaleStrategy.DiscountFixedForYandexMarketFeedback; payload: ProductSalePayloadDiscountFixedForYandexMarketFeedback } |
        { type: ProductSaleStrategy.DiscountPercentsForYandexMarketFeedback; payload: ProductSalePayloadDiscountPercentsForYandexMarketFeedback } |
        { type: ProductSaleStrategy.DiscountFixedForPromoCode; payload: ProductSaleDiscountFixedForPromoCodePayload } |
        { type: ProductSaleStrategy.DiscountPercentsForPromoCode; payload: ProductSaleDiscountPercentsForPromoCodePayload }
    ;

    export type ProductSalePayload =
        ProductSalePayloadNone |
        ProductSalePayloadDiscountFixed |
        ProductSalePayloadDiscountPercents |
        ProductSalePayloadDiscountFixedForYandexMarketFeedback |
        ProductSalePayloadDiscountPercentsForYandexMarketFeedback;

    export class ProductSalePayloadNone {
    }

    export class ProductSalePayloadDiscountFixed {
        @ApiModelProperty()
        @IsPositive()
        @IsNumber()
        @IsNotEmpty()
        discountFixed: number;

        @ApiModelProperty({
            required: false,
        })
        @IsPositive()
        @IsNumber()
        @IsOptional()
        minAmount?: number;
    }

    export class ProductSalePayloadDiscountPercents {
        @ApiModelProperty()
        @IsPositive()
        @IsNumber()
        @IsNotEmpty()
        discountPercents: number;

        @ApiModelProperty({
            required: false,
        })
        @IsPositive()
        @IsNumber()
        @IsOptional()
        minAmount?: number;
    }

    export class ProductSalePayloadDiscountFixedForYandexMarketFeedback {
        @ApiModelProperty()
        @IsPositive()
        @IsNumber()
        @IsNotEmpty()
        discountFixed: number;

        @ApiModelProperty({
            required: false,
        })
        @IsPositive()
        @IsNumber()
        @IsOptional()
        minAmount?: number;
    }

    export class ProductSalePayloadDiscountPercentsForYandexMarketFeedback {
        @ApiModelProperty()
        @IsPositive()
        @IsNumber()
        @IsNotEmpty()
        discountPercents: number;

        @ApiModelProperty({
            required: false,
        })
        @IsPositive()
        @IsNumber()
        @IsOptional()
        minAmount?: number;
    }

    export class ProductSaleDiscountFixedForPromoCodePayload {
        @ApiModelProperty()
        @IsString()
        @IsNotEmpty()
        code: string;

        @ApiModelProperty()
        @IsPositive()
        @IsNumber()
        @IsNotEmpty()
        discountFixed: number;

        @ApiModelProperty({
            required: false,
        })
        @IsPositive()
        @IsNumber()
        @IsOptional()
        minAmount?: number;
    }

    export class ProductSaleDiscountPercentsForPromoCodePayload {
        @ApiModelProperty()
        @IsString()
        @IsNotEmpty()
        code: string;

        @ApiModelProperty()
        @IsPositive()
        @IsNumber()
        @IsNotEmpty()
        discountPercents: number;

        @ApiModelProperty({
            required: false,
        })
        @IsPositive()
        @IsNumber()
        @IsOptional()
        minAmount?: number | undefined;
    }
}

export namespace ProductMarketplaceShared {
    export namespace YandexMarket {
        // tslint:disable-next-line:no-shadowed-variable
        export class ExportRequest {
            @ApiModelProperty()
            @IsPositive({ each: true })
            @IsInt({ each: true })
            @IsArray()
            @IsNotEmpty()
            deliveryMarketplaces: Array<number>;

            @ApiModelProperty()
            @IsPositive({ each: true })
            @IsInt({ each: true })
            @IsArray()
            @IsNotEmpty()
            pickupMarketplaces: Array<number>;

            @ApiModelProperty()
            @IsString()
            @IsOptional()
            salesNotes?: string;

            @ApiModelProperty()
            @IsBoolean()
            @IsNotEmpty()
            delivery: boolean;

            @ApiModelProperty()
            @IsBoolean()
            @IsNotEmpty()
            pickup: boolean;
        }

        export enum CategoryType {
            Own = 'Own',
        }
    }

    export namespace Google {
        export enum Availability {
            InStock = 'in stock',
            PreOrder = 'preorder',
        }

        export enum Condition {
            New = 'new',
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class ExportRequest {
            @ApiModelProperty({
                 type: 'string',
                enum: Object.values(Availability),
            })
            @IsEnum(Availability)
            @IsNotEmpty()
            availability: Availability;

            @ApiModelProperty()
            @IsString()
            @IsNotEmpty()
            deliveryCost: string;
        }

        export interface Item {
            id: number;
            title: string;
            description: string;
            link: string;
            image_link: string;
            condition: Condition;
            availability: Availability;
            price: string;
            priceCurrency: string;
            shippingPrice: string;
            shippingPriceCurrency: string;
            params: Array<ParamsOption>;
            brand: string;
            mpn: string;
        }

        export interface ParamsOption {
            name: string;
            value: string;
        }
    }

    export namespace PP {
        // tslint:disable-next-line:no-shadowed-variable
        export class ExportRequest {
            @ApiModelProperty()
            @IsPositive()
            @IsInt()
            @IsNotEmpty()
            minQuantity: number;

            @ApiModelProperty()
            @IsPositive({ each: true })
            @IsInt({ each: true })
            @IsArray()
            @IsNotEmpty()
            deliveryMarketplaces: Array<number>;

            @ApiModelProperty()
            @IsString()
            @IsNotEmpty()
            offerBeginFrom: string;

            @ApiModelProperty()
            @IsString()
            @IsNotEmpty()
            offerEndsAt: string;

            @ApiModelProperty({
                required: false,
            })
            @IsString()
            @IsOptional()
            salesNotes?: string;
        }

        export enum PPRegion {
            Moscow = 'Moscow',
        }

        export interface PPRegionOption {
            type: PPRegion;
            id: number;
        }

        export const ppRegionOptions: Array<PPRegionOption> = [
            { type: PPRegion.Moscow, id: 504 },
        ];

        export enum CategoryType {
            Own = 'Own',
        }
    }

    export class ExportToYandexMarketRequest {
        @ApiModelProperty()
        @IsNotEmpty()
        @IsBoolean()
        enabled: boolean;

        @ApiModelProperty()
        @ValidateNested()
        @IsObject()
        @IsOptional()
        exportRequest?: YandexMarket.ExportRequest;
    }

    export class ExportToGoogleRequest {
        @ApiModelProperty()
        @IsNotEmpty()
        @IsBoolean()
        enabled: boolean;

        @ApiModelProperty()
        @ValidateNested()
        @IsObject()
        @IsOptional()
        exportRequest?: Google.ExportRequest;
    }

    export class ExportToPPRequest {
        @ApiModelProperty()
        @IsNotEmpty()
        @IsBoolean()
        enabled: boolean;

        @ApiModelProperty()
        @ValidateNested()
        @IsObject()
        @IsOptional()
        exportRequest?: PP.ExportRequest;
    }

    export class ExportRequest {
        @ApiModelProperty({
            required: false,
        })
        @ValidateNested()
        @IsObject()
        @IsOptional()
        exportToYandexMarket?: ExportToYandexMarketRequest;

        @ApiModelProperty({
            required: false,
        })
        @ValidateNested()
        @IsObject()
        @IsOptional()
        exportToGoogle?: ExportToGoogleRequest;

        @ApiModelProperty({
            required: false,
        })
        @ValidateNested()
        @IsObject()
        @IsOptional()
        exportToPP?: ExportToPPRequest;
    }
}
