<a class="c-dealer" rel="nofollow" *ngIf="hasDealer && manufacturerRouterLink" [routerLink]="manufacturerRouterLink || '#'">
  <div class="c-box"> 
    <div class="c-dealer-image" *ngIf="resolvedData?.manufacturer">
      <div class="image-container">
        <img 
          [src]="resolvedData?.manufacturer?.dealerImage?.url || resolvedData?.manufacturer?.image?.url || ''" />
      </div>
    </div>  
    <div class="c-dealer-title" *ngIf="cleanedDealerDescription" [innerHTML]="cleanedDealerDescription"></div>

  </div>
</a>
