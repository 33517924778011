/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output } from '@angular/core';
import { AccountSettingsShared, ProductCategorySeoLinkDto, ProductESDto, ProductSearchRequest, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { OnPaginatorChangeEvent, PaginatorCanonicalUrlGenerator, PaginatorUrlGenerator } from '@interid/interid-site-web/ui-shared';
import { AppLoadingService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { ProductListSort, ProductListViewMode } from '../../models/product-list.models';
import { AppSessionService } from '@interid/interid-site-web/core-session';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CompareTopbarVariant } from '@interid/interid-site-web/ui-shared-product-cards';
import { toSignal } from '@angular/core/rxjs-interop';
import { signalGetFrom } from '@interid/interid-site-web/ui-signals';

@Component({
    selector: 'app-shared-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();
    s
    public products = input<Array<ProductESDto>>();
    public searchRequest = input<ProductSearchRequest>();

    public withSortAndView = input(false);
    public withLoadMore = input(false);
    public withPaginator = input(false);
    public manufacturer: any = input<any>();
    public isVendorPage = input(false);
    public compareTopbarVariant = input<CompareTopbarVariant>('none');

    public hasMoreToLoad = input(false);

    public paginatorPage = input<number>();
    public paginatorPageSize = input<number>();
    public paginatorTotal = input<number>();
    public paginatorUrlGenerator = input<PaginatorUrlGenerator>();
    public paginatorCanonicalUrlGenerator = input<PaginatorCanonicalUrlGenerator>();
    public paginatorShouldFollowUrl = input(false);
    public shouldDisplaySeoLinks = input<boolean>();

    public categoryTopSeoLinks = input<Array<ProductCategorySeoLinkDto>>();
    public blacklistSort = input<Array<ProductListSort>>([]);
    public categoryId = input<number>();

    public shouldShowNoReviewsMessage = input(true);
    public shouldShowFiveEmptyStars = input(false);

    @Output('updateSearchRequest') updateSearchRequestEvent: EventEmitter<ProductSearchRequest> = new EventEmitter<ProductSearchRequest>();
    @Output('paginatorNext') paginatorNextEvent: EventEmitter<OnPaginatorChangeEvent> = new EventEmitter<OnPaginatorChangeEvent>();
    @Output('loadMore') loadMoreEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('filterScreen') filterScreenEvent: EventEmitter<void> = new EventEmitter<void>();

    public isLoading = signalGetFrom<boolean>(this.appLoading.isLoading$);

    public isMobile = signalGetFrom<boolean>(this.viewBreakpointService.currentLayout$.pipe(
        map((next) => [
            ViewBreakpointsShared.Layout.Mobile,
        ].includes(next)),
    ));

    public viewMode = toSignal<AccountSettingsShared.ViewMode>(this.session.accountSettings$.pipe(
        map((settings) => settings.catalogViewModeR2),
        map((viewMode) => viewMode || AccountSettingsShared.defaultCatalogViewMode),
        distinctUntilChanged(),
    ));

    public productListNgClass = computed<string[]>(() => {
        const next = this.viewMode();

        return [`view-mode-${next}`];
    });

    public isCardView = computed<boolean>(() => {
        const next = this.viewMode();

        return next === ProductListViewMode.Card;
    });

    public isListView = computed<boolean>(() => {
        const next = this.viewMode();

        return next === ProductListViewMode.List
    });

    constructor(
        private readonly appLoading: AppLoadingService,
        private readonly viewBreakpointService: ViewBreakpointsService,
        private readonly session: AppSessionService,
    ) {
    }

    trackById<T = number | string>(index: number, input: { id: T }): T {
        return input.id;
    }

    t(input: string): string {
        return `product.shared.components.product_list.${input}`;
    }
}
