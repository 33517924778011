import {ChangeDetectionStrategy, Component, computed, EventEmitter, input, Input, Output} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductCardHelper } from '../../helpers/product-card.helper';
import { ProductRequestModalComponent, ProductRequestModalRequest } from '@interid/interid-site-web/ui-shared';
import { defaultModalConfig, EnvironmentWebService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { ProductGetResponse, RoistatProductRequestContext, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { InteridWebSearchDataAccess } from '@interid/interid-site-data-access/web';
import { ProductAnalogsModalComponent, ProductAnalogsModalRequest } from '../product-analogs-modal/product-analogs-modal.component';

type ButtonWidth = 'auto' | '100';
type FontSize = 'default' | 'small' | 'tiny';

export { FontSize as ProductCardBuyButtonComponentFontSize };
export { ButtonWidth as ProductCardBuyButtonComponentButtonWidth };

@Component({
    selector: 'app-shared-product-card-assets-buy-button',
    templateUrl: './product-card-buy-button.component.html',
    styleUrls: ['./product-card-buy-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardBuyButtonComponent{
    @Input() fontSize: FontSize = 'default';
    @Input() buttonWidth: ButtonWidth = '100';
    @Input() productId? = 0;
    @Input() currentId? = 0;
    @Output() emitOnProductAddedToCard: EventEmitter<void> = new EventEmitter<void>();
    public resolvedData = input<ProductGetResponse>();

    constructor(private readonly env: EnvironmentWebService, private readonly vbs: ViewBreakpointsService, private readonly endpoint: InteridWebSearchDataAccess, private readonly i18n: TranslateService, public readonly helper: ProductCardHelper, private readonly matDialog: MatDialog) {}

    amount: number;

    public checkPriceStep = computed<boolean>(() => {
        if (this.resolvedData()?.product?.priceStep && this.resolvedData()?.product?.type === 2) {
            this.amount = parseFloat(this.resolvedData().product.priceStep);
            return true;
        }
        return false;
    });

    basketEntryCost(): string {
        return (parseFloat(this.helper.product.price) * this.amount).toFixed(2);
    }

    t(input: string): string {
        return `product_shared.assets.product_card_buy_button.${input}`;
    }

    buy(): void {
        if (this.helper.product.priceStep && this.helper.product.type === 2) {
            this.helper.basket.setBasketAmount(this.amount).toPromise().then();
        } else {
            this.helper.basket.addToBasket();
        }

        this.emitOnProductAddedToCard.emit();
    }

    async setBasketAmount(number) {
        const priceStep = parseFloat(this.helper.product.priceStep);
        let amount = 0;
        if (parseFloat(number) < 0) amount = this.amount - priceStep;
        if (parseFloat(number) > 0) amount = this.amount + priceStep;

        if (parseFloat(number) == -1 && (amount == 0 || amount < priceStep)) {
            this.amount = priceStep;
        } else {
            this.amount = amount;
        }
    }

    openAnalogsDialog(): void {
        this.endpoint
            .searchProducts({
                filters: {
                    ids: this.helper.product.productAnalogs.map((x) => x.id),
                },
            })
            .toPromise()
            .then((x) => {
                if (this.vbs.currentLayout == ViewBreakpointsShared.Layout.Mobile) {
                    this.matDialog.open<ProductAnalogsModalComponent, ProductAnalogsModalRequest>(ProductAnalogsModalComponent, {
                        ...defaultModalConfig,
                        maxWidth: '100vw',
                        maxHeight: '100vh',
                        height: '100%',
                        width: '100%',
                        disableClose: false,
                        data: {
                            productAnalogs: x.products,
                            showButtons: false,
                        },
                    });
                } else {
                    this.matDialog.open<ProductAnalogsModalComponent, ProductAnalogsModalRequest>(ProductAnalogsModalComponent, {
                        ...defaultModalConfig,
                        disableClose: false,
                        data: {
                            productAnalogs: x.products,
                            showButtons: true,
                        },
                    });
                }
            });
    }

    productRequest(): void {
        this.matDialog.open<ProductRequestModalComponent, ProductRequestModalRequest>(ProductRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            data: {
                withMessage: this.i18n.instant(this.t('orderWithProductRequestMessage'), {
                    product: this.helper.product,
                    url: `${this.env.current.baseUrl}/kupit/${this.helper.product.id}`,
                }),
                context: RoistatProductRequestContext.ProductOneClickBuy,
                productId: this.helper.product.id,
                productPrice: this.helper.product.price,
            },
        });
    }

    get hasPrice(): boolean {
        return this.helper.price.hasPrice;
    }

    get analogsProductsExist(): boolean {
        return this.helper.product.productAnalogs && this.helper.product.productAnalogs.length != 0;
    }

    get isOutOfProduction(): boolean {
        return this.helper.product.flags.isOutOfProduction;
    }

    get buttonNgClass(): any {
        return [`font-size-${this.fontSize}`, `width-${this.buttonWidth}`];
    }

    get isCustomOrder(): boolean {
        return !this.isAvailableDepot && !this.isAvailableVendorDepot;
    }

    get isAvailableDepot(): boolean {
        return this.helper.product.flags.isAvailableDepot;
    }

    get isAvailableVendorDepot(): boolean {
        return this.helper.product.flags.isAvailableVendorDepot;
    }

    get title$(): Observable<string> {
        return this.helper.source.product$.pipe(
            map((product) => {
                return this.i18n.instant(
                    (() => {
                        if (!product.inBasket || product.inBasket <= 0) {
                            return this.t('inBasketNone');
                        } else if (product.inBasket === 1) {
                            return this.t('inBasketSingle');
                        } else {
                            return this.t('inBasketMulti');
                        }
                    })(),
                    {
                        count: product.inBasket,
                    }
                );
            })
        );
    }
}
