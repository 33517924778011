@if (helper.isAvailable) {
  @if (!!product()) {
    <div class="c" [ngClass]="ngClass">
      <div class="c-preview">
        @if (compareTopbarVariant() === 'simple' || compareTopbarVariant() === 'default') {
          <div class="c-topbar">
            <app-shared-product-card-assets-favorite-compare-topbar></app-shared-product-card-assets-favorite-compare-topbar>
          </div>
        }
        <div class="c-image">
          @if (!!product().preview) {
            <a class="c-image-container" [routerLink]="helper.productRouterLink" (click)="click()" [target]="linkTarget()">
              <app-shared-product-card-assets-preview [maxImageWidth]="520"></app-shared-product-card-assets-preview>
            </a>
          }
          @if (analogBage() || (withBadges() && helper.price.hasBadges)) {
            <div class="c-image-badges">
              <app-shared-product-card-assets-badges-a [product]="product()" [filter]="productBadges()" [analogBage]="analogBage()"></app-shared-product-card-assets-badges-a>
            </div>
          }
          @if (cheapestBage() || (withBadges() && helper.price.hasBadges)) {
            <div class="c-image-badges">
              <app-shared-product-card-assets-badges-a [product]="product()" [filter]="productBadges()" [cheapestBage]="cheapestBage()"></app-shared-product-card-assets-badges-a>
            </div>
          }
          @if (withParametersBadges()) {
            <div class="c-image-parameters-badges">
              <app-shared-product-card-assets-parameters-badges [product]="product()"></app-shared-product-card-assets-parameters-badges>
            </div>
          }
        </div>
      </div>
      <div class="c-price-favorite-compare">
        <div class="c-price">
          <app-shared-product-card-assets-price-a [product]="product()" [labelenabled]="true" [labelcolor]="1" [variant]="variant()"></app-shared-product-card-assets-price-a>
        </div>
      </div>
      <a class="c-title" [routerLink]="helper.productRouterLink" (click)="click()" [target]="linkTarget()"
        ><span>{{ product().title }}</span></a
        >
        @if (withRating() || withReviews()) {
          <div class="c-rating">
            <app-shared-product-card-assets-rating-a [shouldShowFiveEmptyStars]="shouldShowFiveEmptyStars()" [shouldShowNoReviewsMessage]="shouldShowNoReviewsMessage()" [product]="product()" [withRating]="withRating()" [withReviews]="withReviews()" [withIsAvailableOnDepot]="withIsAvailableOnDepot()"></app-shared-product-card-assets-rating-a>
          </div>
        }
        @if (withParams() && helper.params.hasProductParams) {
          <div class="c-params">
            <app-shared-product-card-assets-params-a [product]="product()" [viewmode]="'card'"></app-shared-product-card-assets-params-a>
          </div>
        }
        <div class="__app-fluid-container-control-padding-bottom"></div>
        <div class="c-price-favorite-compare">
          <div class="c-favorite-compare">
            <app-shared-product-card-assets-favorite-compare-a [variant]="2" [withBasket]="withBasket()" [withShare]="withShare()" [withFavorite]="withFavorite()" [withCompare]="withCompare()"></app-shared-product-card-assets-favorite-compare-a>
          </div>
        </div>
      </div>
    }
  }
