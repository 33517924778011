export function loadScript(dynamicScripts: Array<string>) {
    let isFound = false;
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
            isFound = true;
        }
    }

    if (!isFound) { 
        for (let i = 0; i < dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }
}

export function loadScriptAsync(scriptUrls: Array<string>): Promise<void> {
    return new Promise((resolve, reject) => {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');

        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') !== null && scripts[i].getAttribute('src').includes('cdek-widget')) {
                isFound = true;
            }
        }

        if (!isFound) { 
            let loadedScripts = 0;

            for (let i = 0; i < scriptUrls.length; i++) {
                const script = document.createElement('script');
                script.src = scriptUrls[i];
                script.type = 'text/javascript';
                script.async = false;
                script.charset = 'utf-8';
 
                script.onload = () => {
                    loadedScripts++;
                    if (loadedScripts === scriptUrls.length) {
                        resolve(); 
                    }
                };

                script.onerror = (error) => reject(error);  

                document.getElementsByTagName('head')[0].appendChild(script);
            }
        } else {
            resolve(); 
        }
    });
}

export function removeScript(scriptUrl: string): void {
    const scripts = document.getElementsByTagName('script');
    
    for (let i = scripts.length - 1; i >= 0; i--) {
        if (scripts[i].src.includes(scriptUrl)) {
            scripts[i].parentNode?.removeChild(scripts[i]);
        }
    }
}
