/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Input, Renderer2, Inject, OnInit, PLATFORM_ID, Optional, OnDestroy, ChangeDetectorRef, input, signal, computed } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppBootstrapDataService, EnvironmentWebService } from '@interid/interid-site-web/core';
import { Request } from 'express';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { REQUEST } from '@interid/interid-site-web/ui-shared';
import { isPlatformServer } from '@angular/common';
import { CityDto, withoutEndingSlash } from '@interid/interid-site-shared';
import { Subject } from 'rxjs';

export interface BreadcrumbsDefinitions {
    nodes: Array<BreadcrumbsNode>;
}

export interface BreadcrumbsNode {
    routerLink?: any;
    queryParams?: any;
    name: {
        title: string;
        translate: boolean;
        translateParams?: any;
    };
}

interface State {
    city: CityDto;
}

@Component({
    selector: 'app-shared-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public definition = input<BreadcrumbsDefinitions>();
    public disableLastRouterLink = input(true);
    public style = input<'default' | 'article'>('default');

    public state = signal<State>({
        city: new CityDto(),
    });

    public hasDefinitions = computed<boolean>(() => {
        this.createScriptForGoogle()
        return !!this.definition() && !!this.definition().nodes && Array.isArray(this.definition().nodes) && this.definition().nodes.length > 0;
    });

    constructor(private readonly cdr: ChangeDetectorRef, @Optional() @Inject(REQUEST) protected request: Request, private readonly appBootstrap: AppBootstrapDataService, @Inject(PLATFORM_ID) private platformId: Object, private readonly env: EnvironmentWebService, private renderer: Renderer2, @Inject(DOCUMENT) private _document: Document, private readonly translate: TranslateService, private readonly router: Router) {}

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.state.update((prev) => ({
            ...prev,
            city: this.appBootstrap.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find((x) => x.isDefault),
        }));

        if (!isPlatformServer(this.platformId)) this.cdr.markForCheck();
    }

    createScriptForGoogle() {
        if (!isPlatformServer(this.platformId)) this.cdr.markForCheck();
        const regionBaseDomain = this.env.current.regionBaseDomain;
        let baseUrl: string;

        if (this.state().city.isDefault) baseUrl = withoutEndingSlash(`https://${withoutEndingSlash(regionBaseDomain)}`);
        else baseUrl = `https://${this.state().city.domain}.${withoutEndingSlash(regionBaseDomain)}`;

        const oldScript = this._document.getElementById('ld_breadcrumbList');

        if (oldScript) {
            this.renderer.removeChild(this._document.head, oldScript);
        }

        const obj = {};
        obj['@context'] = 'https://schema.org/';
        obj['@type'] = 'BreadcrumbList';
        obj['itemListElement'] = [];
        this.definition().nodes.forEach((e, i) => {
            const item = {};
            item['@type'] = 'ListItem';
            item['position'] = i + 1;

            if (e.name.translate) item['name'] = this.translate.instant(this.t(e.name.title));
            else item['name'] = e.name.title;

            if (e.routerLink) item['item'] = `${baseUrl}${e.routerLink}`;
            else item['item'] = `${baseUrl}${this.router.url}`;
            obj['itemListElement'].push(item);
        });

        const s = this.renderer.createElement('script');
        s.type = 'application/ld+json';
        s.id = 'ld_breadcrumbList';
        s.text = JSON.stringify(obj);
        this.renderer.insertBefore(this._document.head, s, this._document.head.children.item(2));
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `__breadcrumbs.${input}`;
    }

    hasRouterLink(node: BreadcrumbsNode): boolean {
        if (node.routerLink) {
            return !(this.disableLastRouterLink() && this.definition().nodes[this.definition().nodes.length - 1] === node);
        } else {
            return false;
        }
    }
}
