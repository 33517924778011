import * as qs from 'querystring';
import * as _ from 'underscore';
import { ProductSearchFiltersRequest, ProductSearchRequest, ProductSearchSortRequest, ProductSearchViewRequest } from '../../endpoints/product.endpoint';
import { ProductShared } from '../shared/product.shared';
import { ParamsShared } from '../shared/params.shared';
 
interface Options {
  withCategoryId: boolean;
}

const defaultOptions: Options = {
  withCategoryId: false,
};

function paramIsNotEmpty(input: any): boolean {
  return input !== undefined && input !== null && (input || '').toString().trim().length > 0;
}

namespace Shared {
  export class Command {
    name: string;
    value: Array<string>;
  }

  export class ParseFromUrlRequest {
    url: string;
  }

  export class ParseQueryStringRequest {
    queryString: string;
  }

  export class ParsePlainObjectRequest {
    queryObject: Object;
  }

  export type ParseProductESSearchRequest = ProductSearchRequest;

  export function isTruthy(input: string): boolean {
    return ParamsShared.allAvailableTruthyBooleanValue.includes(input.toString());
  }

  export function parseIds(input: Array<string>): Array<number> {
    return _.uniq(input.join(',').split(',').map((id) => parseInt(id, 10)));
  }

  export function idsToString(input: Array<number>): string {
    return _.uniq(input).join(',');
  }

  export function booleanToString(input: boolean): string {
    return input ? '1' : '0';
  }

  export function isNotNullOrUndefined(input: any): boolean {
    return ! (input === undefined ||
      input === null ||
      input === '' ||
      (Array.isArray(input) && input.length === 0)
    );
  }
}

export { Shared as ProductEsSearchUrlGeneratorHelperShared };

export class ProductEsSearchUrlGeneratorHelper {
  private commands: Array<Shared.Command> = [];

  parseFromUrlRequest(request: Shared.ParseFromUrlRequest): void {
    this.commands = [];

    const matchQPs = request.url.match(/\?(.*)$/);

    if (matchQPs && matchQPs[1]) {
      this.parseQueryStringRequest({
        queryString: matchQPs[1],
      });
    }
  }

  parseQueryStringRequest(request: Shared.ParseQueryStringRequest): void {
    this.commands = [];

    const parsed = qs.parse(request.queryString);

    for (const command of Object.keys(parsed)) {
      const value = parsed[command];

      this.appendCommand(command
        .split('[').join('')
        .split(']').join(''), value);
    }
  }

  parsePlainObject(request: Shared.ParsePlainObjectRequest): void {
    this.commands = [];

    if (! request.queryObject) {
      return;
    }

    Object.keys(request.queryObject).forEach((key) => {
      this.commands.push({
        name: key,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
        value: Array.isArray(request.queryObject[key]) ? request.queryObject[key] : [request.queryObject[key]],
      });
    });
  }

  parseProductESSearchRequest(request: Shared.ParseProductESSearchRequest): void {
    this.commands = [];

    if (request && request.view) {
      if (request.view.limit) {
        this.setCommand('limit', [request.view.limit.toString()]);
      }

      if (request.view.offset) {
        this.setCommand('offset', [request.view.offset.toString()]);
      }
    }

    if (request && request.sort) {
      for (const sortRequest of _.uniq(request.sort, (s) => s.field)) {
        this.commands.push({
          name: 'sort',
          value: [`${sortRequest.field}-${sortRequest.direction.toLowerCase()}`],
        });
      }
    }

    if (request && request.queryString) {
      if (Shared.isNotNullOrUndefined(request.queryString)) {
        this.setCommand('q', request.queryString);
      }
    }
 
    if (request && request.filters) { 
 
      if (Shared.isNotNullOrUndefined(request.filters.priceRangeMax) && request.filters.priceRangeMax != "0") {
        this.setCommand('priceRangeMax', request.filters.priceRangeMax);
      } 
      
      if (Shared.isNotNullOrUndefined(request.filters.priceRangeMin) && request.filters.priceRangeMin != "0") {
        
        this.setCommand('priceRangeMin', request.filters.priceRangeMin);
      }

      if (Shared.isNotNullOrUndefined(request.filters.ids)) {
        this.setCommand('ids', Shared.idsToString(request.filters.ids));
      }

      if (Shared.isNotNullOrUndefined(request.filters.excludeIds)) {
        this.setCommand('exclude_ids', Shared.idsToString(request.filters.excludeIds));
      }

      if (Shared.isNotNullOrUndefined(request.filters.manufacturerIds)) {
        this.setCommand('manufacturer_id', Shared.idsToString(request.filters.manufacturerIds));
      }

      if (Shared.isNotNullOrUndefined(request.filters.productCategoryIds)) {
        this.setCommand('category_id', Shared.idsToString(request.filters.productCategoryIds));
      }

      if (Shared.isNotNullOrUndefined(request.filters.productSeriesIds)) {
        this.setCommand('series_id', Shared.idsToString(request.filters.productSeriesIds));
      }

      if (Shared.isNotNullOrUndefined(request.filters.tagIds)) {
        this.setCommand('tag_ids', Shared.idsToString(request.filters.tagIds));
      }

      if (Shared.isNotNullOrUndefined(request.filters.type)) {
        this.setCommand('type', request.filters.type.toString());
      }

      if (Shared.isNotNullOrUndefined(request.filters.partNumber)) {
        this.setCommand('part_number', request.filters.partNumber.toString());
      }

      if (Shared.isNotNullOrUndefined(request.filters.uuid)) {
        this.setCommand('uuid', request.filters.uuid.toString());
      }

      if (Shared.isNotNullOrUndefined(request.filters.uuid)) {
        this.setCommand('uuid', request.filters.uuid.toString());
      }

      if (Shared.isNotNullOrUndefined(request.filters.vendorCode)) {
        this.setCommand('vendor_code', request.filters.vendorCode.toString());
      }

      if (Shared.isNotNullOrUndefined(request.filters.vendorCode1C)) {
        this.setCommand('vendor_code_1c', request.filters.vendorCode1C.toString());
      }

      if (Shared.isNotNullOrUndefined(request.filters.title)) {
        this.setCommand('title', request.filters.title.toString());
      }

      if (Shared.isNotNullOrUndefined(request.filters.isEnabled)) {
        this.setCommand('is_enabled', Shared.booleanToString(request.filters.isEnabled));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isAvailableDepot)) {
        this.setCommand('is_available_depot', Shared.booleanToString(request.filters.isAvailableDepot));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isAvailable)) {
        this.setCommand('is_available', Shared.booleanToString(request.filters.isAvailable));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isAvailableVendorDepot)) {
        this.setCommand('is_available_on_vendor_depot', Shared.booleanToString(request.filters.isAvailableVendorDepot));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isStaticAvailableVendorDepot)) {
        this.setCommand('is_static_available_on_vendor_depot', Shared.booleanToString(request.filters.isStaticAvailableVendorDepot));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isShowedOnHomepage)) {
        this.setCommand('is_showed_on_homepage', Shared.booleanToString(request.filters.isShowedOnHomepage));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isNew)) {
        this.setCommand('is_new', Shared.booleanToString(request.filters.isNew));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isCustomerChoice)) {
        this.setCommand('is_customer_choice', Shared.booleanToString(request.filters.isCustomerChoice));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isHit)) {
        this.setCommand('is_hit', Shared.booleanToString(request.filters.isHit));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasPrice)) {
        this.setCommand('has_price', Shared.booleanToString(request.filters.hasPrice));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasNoPrice)) {
        this.setCommand('has_no_price', Shared.booleanToString(request.filters.hasNoPrice));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isArchived)) {
        this.setCommand('is_archived', Shared.booleanToString(request.filters.isArchived));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isPopular)) {
        this.setCommand('is_popular', Shared.booleanToString(request.filters.isPopular));
      }

      if (Shared.isNotNullOrUndefined(request.filters.shouldHideParameters)) {
        this.setCommand('should_hide_parameters', Shared.booleanToString(request.filters.shouldHideParameters));
      }

      if (Shared.isNotNullOrUndefined(request.filters.exportAvailableDepotToMarket)) {
        this.setCommand('export_available_depot_to_market', Shared.booleanToString(request.filters.exportAvailableDepotToMarket));
      }

      if (Shared.isNotNullOrUndefined(request.filters.autoExportToYandexIfAvailable)) {
        this.setCommand('auto_export_to_yandex_if_available', Shared.booleanToString(request.filters.autoExportToYandexIfAvailable));
      }

      if (Shared.isNotNullOrUndefined(request.filters.exportAvailableToMarket)) {
        this.setCommand('export_available_to_market', Shared.booleanToString(request.filters.exportAvailableToMarket));
      }

      if (Shared.isNotNullOrUndefined(request.filters.shouldHideUnderCut)) {
        this.setCommand('should_hide_under_cut', Shared.booleanToString(request.filters.shouldHideUnderCut));
      }

      if (Shared.isNotNullOrUndefined(request.filters.isOutOfProduction)) {
        this.setCommand('is_out_of_production', Shared.booleanToString(request.filters.isOutOfProduction));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasExportToYandexMarket)) {
        this.setCommand('has_export_to_yandex_market', Shared.booleanToString(request.filters.hasExportToYandexMarket));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasExportToGoogle)) {
        this.setCommand('has_export_to_google', Shared.booleanToString(request.filters.hasExportToGoogle));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasExportToPP)) {
        this.setCommand('has_export_to_pp', Shared.booleanToString(request.filters.hasExportToPP));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasExportToYandexMarket)) {
        this.setCommand('has_export_to_yandex_market', Shared.booleanToString(request.filters.hasExportToYandexMarket));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasMinimalPriceViolation)) {
        this.setCommand('has_minimal_price_violation', Shared.booleanToString(request.filters.hasMinimalPriceViolation));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasSale)) {
        this.setCommand('has_sale', Shared.booleanToString(request.filters.hasSale));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasDiscounted)) {
        this.setCommand('has_discounted', Shared.booleanToString(request.filters.hasDiscounted));
      }

      if (Shared.isNotNullOrUndefined(request.filters.hasMarkingkits)) {
        this.setCommand('has_markingkits', Shared.booleanToString(request.filters.hasMarkingkits));
      }

      if (Shared.isNotNullOrUndefined(request.filters.productParams)) {
        for (const paramsRequest of request.filters.productParams.filter((pp) => paramIsNotEmpty(pp.paramsStaticValue))) {
          this.appendCommand(`param${paramsRequest.paramsId}`, paramsRequest.paramsStaticValue);
        }
      }
    }
  }

  toPlainObject(options: Options = defaultOptions): qs.ParsedUrlQuery {
    const queryParams = this.toQueryString(options); 
    return qs.parse(queryParams);
  }

  toQueryString(options: Options = defaultOptions): string {
    const qsObject: { [param: string]: Array<string> } = {};

    const sortedCommands = options.withCategoryId
      ? [...this.commands]
      : [...this.commands.filter((c) => c.name !== 'category_id')];

    sortedCommands.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name === b.name) {
        return 0;
      } else {
        return 1;
      }
    });

    for (const command of sortedCommands) {
      if (! qsObject[command.name]) {
        qsObject[command.name] = [];
      }

      qsObject[command.name].push(...command.value);
    }

    return qs.encode(qsObject);
  }

  toProductESSearchObject(): ProductSearchRequest {
    let queryString: string;

    const view: ProductSearchViewRequest = {};
    const sort: Array<ProductSearchSortRequest> = [];
    const filters: ProductSearchFiltersRequest = {};

    for (const command of this.commands) {
      if (command.name.indexOf('params') === 0) {
        const paramId = parseInt(command.name.replace('params', ''), 10);

        if (! filters.productParams) {
          filters.productParams = [];
        }

        for (const paramsStaticValue of _.uniq(command.value)) {
          const hasParam = filters.productParams.find(
            (p) => p.paramsId === paramId && p.paramsStaticValue === paramsStaticValue,
          );

          if (! hasParam) {
            filters.productParams.push({
              paramsId: paramId,
              paramsStaticValue,
            });
          }
        }

        if (filters.productParams.length === 0) {
          filters.productParams = undefined;
        }
      } else if (command.name.indexOf('param') === 0) {
        const paramId = parseInt(command.name.replace('param', ''), 10);

        if (! filters.productParams) {
          filters.productParams = [];
        }

        for (const paramsStaticValue of _.uniq(command.value).filter((pp) => paramIsNotEmpty(pp))) {
          const hasParam = filters.productParams.find(
            (p) => p.paramsId === paramId && p.paramsStaticValue === paramsStaticValue,
          );

          if (! hasParam) {
            filters.productParams.push({
              paramsId: paramId,
              paramsStaticValue,
            });
          }
        }

        if (filters.productParams.length === 0) {
          filters.productParams = undefined;
        }
      } else {
        switch (command.name) {
          case 'limit': {
            view.limit = parseInt(command.value[0], 10);

            break;
          }

          case 'offset': {
            view.offset = parseInt(command.value[0], 10);

            break;
          }

          case 'sort': {
            for (const sortQuery of command.value) {
              const items = sortQuery.split('-');

              const fieldName: ProductShared.ProductSearchSort = items[0] as ProductShared.ProductSearchSort;
              const direction: ProductShared.ProductSearchSortDirection = items[1].toUpperCase() as ProductShared.ProductSearchSortDirection;

              if (! Object.values(ProductShared.ProductSearchSortDirection).includes(direction)) {
                throw new Error(`Invalid direction "${direction}"`);
              }

              sort.push({
                field: fieldName,
                direction: direction,
              });
            }

            break;
          }

          case 'q': {
            queryString = command.value[0];

            break;
          }

          case 'id':
          case 'ids': {
            filters.ids = Shared.parseIds(command.value);

            break;
          }

          case 'exclude_id':
          case 'exclude_ids': {
            filters.excludeIds = Shared.parseIds(command.value);

            break;
          }

          case 'manufacturer_id':
          case 'manufacturer_ids':
          case 'vendors':
          case 'vendor_id':
          case 'vendor_ids': {
            const manufacturerIds = Shared.parseIds(command.value)
              .filter((id) => id > 0);

            if (manufacturerIds.length) {
              filters.manufacturerIds = Shared.parseIds(command.value);
            }

            break;
          }

          case 'category_id':
          case 'category_ids':
          case 'categories_id':
          case 'categories':
          case 'product_categories_id':
          case 'product_categories_ids': {
            filters.productCategoryIds = Shared.parseIds(command.value);

            break;
          }

          case 'tag_ids':
          case 'tags': {
            filters.tagIds = Shared.parseIds(command.value);

            break;
          }

          case 'serie':
          case 'series':
          case 'serie_id':
          case 'series_id':
          case 'serie_ids':
          case 'product_series_id':
          case 'product_series_ids': {
            filters.productSeriesIds = Shared.parseIds(command.value);

            break;
          }

          case 'type': {
            filters.type = parseInt(command.value[0], 10);

            break;
          }

          case 'part_number': {
            filters.partNumber = command.value[0];

            break;
          }

          case 'uuid': {
            filters.uuid = command.value[0];

            break;
          }

          case 'priceRangeMin': {
            filters.priceRangeMin = command.value[0];

            break;
          }

          case 'priceRangeMax': {
            filters.priceRangeMax = command.value[0];

            break;
          }

          case 'vendor_code': {
            filters.vendorCode = command.value[0];

            break;
          }

          case 'vendor_code_1c': {
            filters.vendorCode1C = command.value[0];

            break;
          }

          case 'title': {
            filters.title = command.value[0];

            break;
          }

          case 'is_enabled': {
            filters.isEnabled = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_available': {
            filters.isAvailable = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_available_depot': {
            filters.isAvailableDepot = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_available_on_vendor_depot': {
            filters.isAvailableVendorDepot = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_static_available_on_vendor_depot': {
            filters.isStaticAvailableVendorDepot = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_showed_on_homepage': {
            filters.isShowedOnHomepage = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_new': {
            filters.isNew = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_customer_choice': {
            filters.isCustomerChoice = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_hit': {
            filters.isHit = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_price': {
            filters.hasPrice = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_no_price': {
            filters.hasNoPrice = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_archived': {
            filters.isArchived = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_popular': {
            filters.isPopular = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'should_hide_parameters': {
            filters.shouldHideParameters = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'should_hide_under_cut': {
            filters.shouldHideUnderCut = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'export_available_to_market': {
            filters.exportAvailableToMarket = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'export_available_depot_to_market': {
            filters.exportAvailableDepotToMarket = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'auto_export_to_yandex_if_available': {
            filters.autoExportToYandexIfAvailable = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'is_out_of_production': {
            filters.isOutOfProduction = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_export_to_yandex_market': {
            filters.hasExportToYandexMarket = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_export_to_google': {
            filters.hasExportToGoogle = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_export_to_pp': {
            filters.hasExportToPP = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_minimal_price_violation': {
            filters.hasMinimalPriceViolation = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_sale': {
            filters.hasSale = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_discounted': {
            filters.hasDiscounted = Shared.isTruthy(command.value[0]);

            break;
          }

          case 'has_markingkits': {
            filters.hasMarkingkits = Shared.isTruthy(command.value[0]);

            break;
          }
        }
      }
    }

    const result: ProductSearchRequest = {};
 
    if ((queryString || '').trim().length > 0) {
      result.queryString = queryString;
    }

    if (Object.keys(view).length) {
      result.view = view;
    }

    if (Object.keys(filters).length) {
      result.filters = filters;
    }

    if (sort.length > 0) {
      result.sort = sort;
    }
 
    return result;
  }

  private hasCommand(command: string): boolean {
    return !! this.commands.find((c) => c.name === command);
  }

  private setCommand(command: string, value: Array<string> | string): void {
    if (! this.hasCommand(command)) {
      this.commands.push({
        name: command,
        value: [],
      });
    }

    this.commands.find((c) => c.name === command).value = _.uniq(Array.isArray(value) ? value : [value]);
  }

  private appendCommand(command: string, value: Array<string> | string): void {
    if (! this.hasCommand(command)) {
      this.commands.push({
        name: command,
        value: [],
      });
    }

    const commandObj = this.commands.find((c) => c.name === command);

    commandObj.value = _.uniq([
      ...commandObj.value,
      ...(Array.isArray(value) ? value : [value]),
    ]);
  }
}
