import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';


export class BasePriceCurrencyDto {
  @ApiModelProperty({ nullable: true })
  id?: number;

  @ApiModelProperty({ nullable: true })
  code?: string;

  @ApiModelProperty({ nullable: true })
  exchangeRate?: number;
}

export class ProductCategoryDto {
  @ApiModelProperty({ nullable: true })
  id?: number;
}

export class FlagsDto {
  @ApiModelProperty({ nullable: true })
  isArchived?: boolean;

  @ApiModelProperty({ nullable: true })
  isEnabled?: boolean;
}

export class ManufacturerDto {
  @ApiModelProperty({ nullable: true })
  title?: string;
}

export class ProductAnalyticsDto {
  @ApiModelProperty()
  id: number;

  @ApiModelProperty({ nullable: true })
  partNumber?: string;

  @ApiModelProperty({ nullable: true })
  title?: string;

  @ApiModelProperty({ type: ManufacturerDto, nullable: true })
  manufacturer?: ManufacturerDto | null;

  @ApiModelProperty({ nullable: true })
  price?: string;

  @ApiModelProperty({ nullable: true })
  minimalPrice?: string;

  @ApiModelProperty({ nullable: true })
  interIdAvailable?: number;

  @ApiModelProperty({ nullable: true })
  interIdTotalAmount?: number;

  @ApiModelProperty({ nullable: true })
  basePrice?: string;

  @ApiModelProperty({ type: BasePriceCurrencyDto, nullable: true })
  basePriceCurrency?: BasePriceCurrencyDto | null;

  @ApiModelProperty({ type: ProductCategoryDto, nullable: true })
  productCategory?: ProductCategoryDto | null;

  @ApiModelProperty({ type: FlagsDto, nullable: true })
  flags?: FlagsDto | null;

  @ApiModelProperty({ nullable: true })
  uuid?: string;
}
