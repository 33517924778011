import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { ContentDTO } from '../shared/common-content.shared';
import { ProductCategoryListDto } from './product-category.dto';
import { ProductMarketplaceShared, ProductSaleShared, ProductShared } from '../shared/product.shared';
import { ProductParamsDto } from './product-params.dto';
import { CurrencyDto } from './currency.dto';
import { ProductSeriesDto } from './product-series.dto';
import { AttachmentDto } from './attachment.dto';
import { ManufacturerDto } from './manufacturer.dto';
import { TagDto } from './tag.dto';
import { SortOrderShared } from '../shared/sort-order.shared';
import { PageMetadataSEO } from '../shared/common.shared';
import { ProductPriceLogDto } from '../../nx';

export type ProductId = number;

export enum ProductType {
    Product,
    Accessories,
    Consumable,
    Modification,
}

export const allProductTypeKeys: Array<string> = [
    'Product',
    'Accessories',
    'Consumable',
    'Modification',
];

export const allProductTypes: Array<ProductType> = [
    ProductType.Product,
    ProductType.Accessories,
    ProductType.Consumable,
    ProductType.Modification,
];

export class ProductFlagsDto {
    @ApiModelProperty()
    isEnabled: boolean;

    @ApiModelProperty()
    isArchived: boolean;

    @ApiModelProperty()
    isAvailable: boolean;

    @ApiModelProperty()
    isAvailableDepot: boolean;

    @ApiModelProperty({
        description: 'Is available on vendor depot (https://alytics.interid.ru/api/Reports/Category/1442/GoodsStatistics)',
    })
    isAvailableVendorDepot: boolean;

    @ApiModelProperty()
    isStaticAvailableVendorDepot: boolean;

    @ApiModelProperty()
    isShowedOnHomepage: boolean;

    @ApiModelProperty()
    hasReviews: boolean;

    @ApiModelProperty()
    isNew: boolean;

    @ApiModelProperty()
    isCustomerChoice: boolean;

    @ApiModelProperty()
    isHit: boolean;

    @ApiModelProperty()
    isPopular: boolean;

    @ApiModelProperty()
    shouldHideParameters: boolean;

    @ApiModelProperty()
    exportAvailableToMarket: boolean;

    @ApiModelProperty()
    exportAvailableDepotToMarket: boolean;

    @ApiModelProperty()
    autoExportToYandexIfAvailable: boolean;

    @ApiModelProperty()
    shouldHideUnderCut: boolean;

    @ApiModelProperty()
    isOutOfProduction: boolean;

    @ApiModelProperty()
    withWarrantyFromManufacturer: boolean;

    @ApiModelProperty()
    hasSale: boolean;

    @ApiModelProperty()
    hasDiscounted: boolean;

    @ApiModelProperty()
    hasMarkingkits: boolean;

    @ApiModelProperty()
    hasPrice: boolean;

    @ApiModelProperty()
    enableAutoPriceUpdate: boolean;

    @ApiModelProperty()
    hasNoPriceTax: boolean;
}

export class ProductSeoMetaDto {
    @ApiModelProperty()
    name: string;

    @ApiModelProperty()
    value: string;
}

export class ProductDescriptions {
    @ApiModelProperty()
    description: ContentDTO;

    @ApiModelProperty()
    annotation: string;
}

export class ProductSaleDto {
    @ApiModelProperty()
    strategy: ProductSaleShared.ProductSaleStrategyPair;

    @ApiModelProperty()
    badge: string;

    @ApiModelProperty()
    badgeForCard: string;

    @ApiModelProperty()
    dateFrom?: string;

    @ApiModelProperty()
    dateTo?: string;
}

export class ProductDto implements SortOrderShared.SortableEntity {
    @ApiModelProperty()
    id: ProductId;

    @ApiModelProperty()
    rank: number;

    @ApiModelProperty()
    rankGroup: number;

    @ApiModelProperty()
    dateCreated: number;

    @ApiModelProperty()
    dateUpdated: number;

    @ApiModelProperty()
    datePublished: number | undefined;

    @ApiModelProperty()
    dateArchived: number | undefined;

    @ApiModelProperty()
    sortOrder: number;

    @ApiModelProperty()
    type: ProductType;

    @ApiModelProperty()
    manufacturer: ManufacturerDto;

    @ApiModelProperty()
    productSeries: Array<ProductSeriesDto>;

    @ApiModelProperty()
    productCategory: ProductCategoryListDto;

    @ApiModelProperty()
    productConsumables: Array<ProductDto>;

    @ApiModelProperty()
    productRecommended: Array<ProductDto>;

    @ApiModelProperty()
    productSame: Array<ProductDto>;

    @ApiModelProperty()
    productAccessories: Array<ProductDto>;

    @ApiModelProperty()
    productAnalogs: Array<ProductDto>;

    @ApiModelProperty()
    productPriceLog: Array<ProductPriceLogDto>;

    @ApiModelProperty()
    productParams: Array<ProductParamsDto>;

    @ApiModelProperty()
    tags: Array<TagDto>;

    @ApiModelProperty()
    sale: ProductSaleDto;

    @ApiModelProperty()
    uuid: string;

    @ApiModelProperty()
    partNumber: string;

    @ApiModelProperty()
    vendorCode: string;

    @ApiModelProperty()
    vendorCode1C: string;

    @ApiModelProperty()
    title: string;

    @ApiModelProperty()
    queryTitle: string;

    @ApiModelProperty()
    description: ProductDescriptions;

    @ApiModelProperty()
    uri: string;

    @ApiModelProperty()
    flags: ProductFlagsDto;

    @ApiModelProperty()
    preview: AttachmentDto;

    @ApiModelProperty()
    images: Array<AttachmentDto>;

    @ApiModelProperty()
    files: Array<AttachmentDto>;

    @ApiModelProperty()
    videos: Array<ProductShared.ProductVideo>;

    @ApiModelProperty({
        type: () => PageMetadataSEO,
    })
    seo: PageMetadataSEO;

    @ApiModelProperty()
    basePrice: string;

    @ApiModelProperty()
    basePriceCurrency: CurrencyDto;

    @ApiModelProperty()
    basePriceCurrencyId: number;

    @ApiModelProperty()
    minimalPrice: string;

    @ApiModelProperty()
    price: string;

    @ApiModelProperty()
    priceTax: string;

    @ApiModelProperty()
    tradePrice?: string;

    @ApiModelProperty()
    tradePriceAnnotation?: string;

    @ApiModelProperty()
    priceComment?: string;

    @ApiModelProperty()
    priceStep?: string;

    @ApiModelProperty()
    displayDiscountFrom: string;

    @ApiModelProperty()
    displayDiscountPercents: string;

    @ApiModelProperty()
    warranty: string;

    @ApiModelProperty()
    hits: number;

    @ApiModelProperty()
    hitOrders: number;

    @ApiModelProperty()
    hitsOrderMinimal: number;

    @ApiModelProperty()
    hitFavorites: number;

    @ApiModelProperty()
    rating: number;

    @ApiModelProperty()
    ratingCount: number;

    @ApiModelProperty()
    productCategoryId?: number;

    @ApiModelProperty()
    exportToYandexMarket?: ProductMarketplaceShared.YandexMarket.ExportRequest;

    @ApiModelProperty()
    exportToGoogle?: ProductMarketplaceShared.Google.ExportRequest;

    @ApiModelProperty()
    exportToPP?: ProductMarketplaceShared.PP.ExportRequest;

    @ApiModelProperty()
    interIdAvailable: number;

    @ApiModelProperty()
    interIdTotalAmount?: number;
}

export class ProductESDto extends ProductDto {
    @ApiModelProperty()
    queryString?: string;

    @ApiModelProperty()
    inBasket?: number;

    @ApiModelProperty()
    inFavorites?: boolean;

    @ApiModelProperty()
    inCompares?: boolean;
}
