import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

declare global {
    interface Window {
        CDEKWidget: any;
    }
}

@Component({
    selector: 'app-shared-ui-cdek',
    templateUrl: './ui-cdek.component.html',
    styleUrls: ['./ui-cdek.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UICdekComponent implements AfterViewInit, OnDestroy {
    private widget: any;
    @Input() apiKey = '4fffe1bd-d322-444f-9c21-f5c8bc5cc772';
    @Input() servicePath = 'https://api.interid.ru/api/v1/web/cdek/process';
    @Input() defaultLocation = 'Москва';
    @Input() from = 'Москва';
    @Input() lang = 'rus';
    @Input() root = undefined;
    @Input() currency = 'RUB';
    @Input() canChoose = false;
    @Input() popup = false;
    @Input() goods: any[] = [];
    @Input() hideDeliveryOptions: any = { door: true };
    @Input() tariffs: any = {};
    @Input() autoOpen = false;

    @Output() calculate: EventEmitter<any> = new EventEmitter<any>();
    @Output() choose: EventEmitter<any> = new EventEmitter<any>();

    constructor(private el: ElementRef) {}

    open() {
        this.widget.open();
    }

    ngAfterViewInit() {
        window.CDEKWidget = window.CDEKWidget || {};

        this.loadCDEKWidget();
    }

    ngOnDestroy() {
        this.destroyWidget();
    }

    private loadCDEKWidget() {
        if (!this.widget) {
            this.widget = new window.CDEKWidget({
                root: this.root,
                apiKey: this.apiKey,
                servicePath: this.servicePath,
                defaultLocation: this.defaultLocation,
                from: this.from,
                lang: this.lang,
                currency: this.currency,
                canChoose: this.canChoose,
                popup: this.popup,
                goods: this.goods,
                hideDeliveryOptions: this.hideDeliveryOptions,
                tariffs: this.tariffs,
                onCalculate: (data) => {
                    if (this.calculate.observed) {
                        this.calculate.emit(data);
                    }
                },
                onChoose: (a, b, c) => {
                    if (this.choose.observed) {
                        this.choose.emit({ address: c.address, code: c.code });
                    }
                },
            });

            if (this.autoOpen){
                this.widget.open();
            }
        }
    }

    private destroyWidget() {
        if (this.widget) {
            this.widget.destroy?.();
            this.widget = null;

            if (window.CDEKWidget) {
                delete window.CDEKWidget;
            }
        }
    }
}
